import { Box, Button, FormControl, Input, Label, Paragraph, Stepper, Table, Toast } from "View/Common";
import { Spacer } from "View/Common/Layout/Spacer";
import { STEPS } from '../HomePage'
import { useGetAllDatasets, useGetCurrentStep, useGetOrderSelector } from "Flux/Selector";
import { Translation } from "Translation/Translation";
import { useInteraction } from "Framework/View/Hooks/useInteraction";
import { useEffect, useRef, useState } from "react";
import { BehaviorSubject, Subject } from "rxjs";
import { ContactModal } from "../Modals/ContactModal";
import { CancelModal } from "../Modals/CancelModal";
import Actions, { useDispatch } from 'Flux'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'
import { Heading2 } from "View/Common/Typography/Heading2";
import { IDataSet, INegativeControl, ISample } from "Model";
import { Tooltip } from 'react-tooltip'

type TRow = {
    'name': string,
    'customer_sampleID': string,
    'forward_primer': string,
    'reverse_primer': string,
    'type':string,
    'informationLossMerge':number,
    'informationLossTrimming':number,
    'informationLossFiltering':number,
    'informationLossTrimmingReverse':number,
    'informationLossTrimmingForward':number,
    'sequenceCountTotal':number,
    'sequenceCountMerged':number,
    'sequenceCountForwardTrimmed':number,
    'sequenceCountReverseTrimmed':number,
    'sequenceCountQualityFiltered':number,

};

export const QualityCheckPage = () =>{

    const { translate } = Translation
    const dispatch = useDispatch()

    const currentStep = useGetCurrentStep()
    const order = useGetOrderSelector()
    const datasets = useGetAllDatasets()

    const searchSampleName$ = useRef(new BehaviorSubject('')).current
    const searchLabName$ = useRef(new BehaviorSubject('')).current
    const message$ = useRef(new Subject<{ type: 'success' | 'error' | 'warning' | 'info', message: string }>()).current

    const [isOnThankYouScreen, setIsOnThankYouScreen] = useState<boolean>(false)
    const [sortedData, setSortedData] = useState<TRow[]>();
    const [qualityCheckData, setQualityCheckData] = useState<TRow[]>();

    const onNext$ = useInteraction<void>()
    const onContact$ = useInteraction<void>()
    const onContactSent$ = useInteraction<void>()
    const onCancel$ = useInteraction<void>()

    useEffect(()=>{

        const subscription = onContact$.subscribe(() => {
          message$.next({
            type: 'success',
            message: "Your message has been successfully sent",
          })
        })
        return () => subscription.unsubscribe()
    
      },[onContactSent$])

      useEffect(() => {
        const subscription = onNext$.subscribe(() => {
          setIsOnThankYouScreen(true)
          isOnThankYouScreen && dispatch(
            Actions[orderSlice.name].reset(),
          )
    
        })
    
        return () => subscription.unsubscribe()
      }, [onNext$,isOnThankYouScreen])

      useEffect(()=>{
        if('id' in order){
            setInterval(()=>{
                dispatch(
                    Actions[orderSlice.name].getAllDatasetRequest({orderId: order.id || ''})
                )
            },20000)
        }
      },[order])

      useEffect(()=>{
        if(datasets){
            const convert = (dataset: IDataSet, type:string) => {
                return (sample: ISample | INegativeControl): TRow => ({
                  'name': sample.forwardFile.originalFileName +' '+sample.reverseFile.originalFileName,
                  'customer_sampleID': sample.customerSampleId  || '',
                  'forward_primer': sample.forwardPrimer || '',
                  'reverse_primer': sample.reversePrimer || '',
                  'type': type,
                  'informationLossMerge':sample.informationLossMerge || 0,
                  'informationLossTrimming':sample.informationLossTrimming || 0,
                  'informationLossFiltering':sample.informationLossFiltering || 0,
                  'informationLossTrimmingReverse':sample.informationLossTrimmingReverse || 0,
                  'informationLossTrimmingForward':sample.informationLossTrimmingForward || 0,
                  'sequenceCountTotal':sample.sequenceCountTotal || 0,
                  'sequenceCountMerged':sample.sequenceCountMerged || 0,
                  'sequenceCountForwardTrimmed':sample.sequenceCountForwardTrimmed || 0,
                  'sequenceCountReverseTrimmed':sample.sequenceCountReverseTrimmed || 0,
                  'sequenceCountQualityFiltered':sample.sequenceCountQualityFiltered || 0,
                })
              }
              const sort = datasets.reduce((rows, dataset) => {

                return [
                  ...rows,
                  ...(dataset.samples || []).map(convert(dataset,"Sample")),
                  ...(dataset.negativeControls || []).map(convert(dataset, "NC")),
                ]
              }, [] as (TRow)[])
              setSortedData(sort)
              setQualityCheckData(sort)
        }
      },[datasets])

      useEffect(()=>{
        const subscription = searchSampleName$.subscribe((name:string)=>{
            if(qualityCheckData){
                const data = qualityCheckData.filter((item:TRow)=>{
                    return item.customer_sampleID.toLowerCase().includes(name.toLowerCase()) && item.name.toLowerCase().includes(searchLabName$.value.toLowerCase())
                })
                setSortedData(data)
            }
        })

        return () => subscription.unsubscribe()
      },[searchSampleName$,qualityCheckData,searchLabName$])

      useEffect(()=>{
        const subscription = searchLabName$.subscribe((name:string)=>{
        
            if(qualityCheckData){
                const data = qualityCheckData.filter((item:TRow)=>{
                    return item.name.toLowerCase().includes(name.toLowerCase()) && item.customer_sampleID.toLowerCase().includes(searchSampleName$.value.toLowerCase())
                })
                setSortedData(data)
            }
        })

        return () => subscription.unsubscribe()
      },[searchLabName$, searchSampleName$])

    return(
        <div>
            <Box
            action={
                <>
                    <Button variant='primary' onClick$={onNext$}>{isOnThankYouScreen ? 'Start New Order':translate('home:next')}</Button>
                </>
            }
            >
                <Stepper steps={STEPS} currentStep={currentStep} />
                <Spacer />
                <div className='flex justify-end'>
                    <Button variant='contact' onClick$={onContact$}>Contact</Button>
                </div>
                <div className='flex justify-center items-center'>
                    <Heading2>Order name: {order.name}</Heading2>
                </div>
                <div className='text-center'>
                    <Paragraph>Here you can observe the preprocessing of your samples - this page is automatically refreshed every 20 seconds</Paragraph>
                </div>
                <Spacer/>
                {
                    !isOnThankYouScreen ? <div>
                    <Table>
                        <Table.Head>
                            <Table.Row>
                                <Table.Cell key={'customer_sample_name'}><div className="text-center text-sm font-bold">Customer Sample Name</div></Table.Cell>
                                <Table.Cell key={'type'}><div className="text-center text-sm font-bold"></div></Table.Cell>
                                <Table.Cell key={'forward_primer'}><div className="text-center text-sm font-bold"></div></Table.Cell>
                                <Table.Cell key={'reverse_primer'}><div className="text-center text-sm font-bold"></div></Table.Cell>
                                <Table.Cell key={'quality_assurance_1'}><div className="text-center text-base font-bold"></div></Table.Cell>
                                <Table.Cell key={'quality_assurance_2'}><div className="text-center text-base font-bold"></div></Table.Cell>
                                <Table.Cell key={'quality_assurance_3'}><div className="text-center text-sm font-bold">QA</div></Table.Cell>
                                <Table.Cell key={'quality_assurance_4'}><div className="text-center text-base font-bold"></div></Table.Cell>
                                <Table.Cell key={'quality_assurance_5'}><div className="text-center text-base font-bold"></div></Table.Cell>
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                        <Table.Row key={'0'}>
                            <Table.Cell key={`customer_name_search`}>
                                <FormControl>
                                    <Input type='text' onChange$={searchSampleName$}
                                        placeholder={'Search...'} />
                                </FormControl>
                            </Table.Cell>
                            <Table.Cell key={`type`}>
                            <div className="flex items-center justify-center space-x-3">
                                <div className=" flex items-center justify-center text-xs font-bold">
                                     Type
                                </div>
                            </div>
                            </Table.Cell>
                            <Table.Cell key={`forward_primer_box`}>
                                <div className="text-sm flex items-center justify-center bg-gray-200 text-gray-400 h-12">
                                    Forward Primer
                                </div>
                            </Table.Cell>
                            <Table.Cell key={`reverse_primer_box`}>
                                <div className="text-sm flex items-center justify-center bg-gray-200 text-gray-400 h-12">
                                    Reverse Primer
                                </div>
                            </Table.Cell>
                            <Table.Cell key={`seqs_total`}>
                            <div className="flex items-center justify-center space-x-3">
                                <div className=" flex items-center justify-center text-xs font-bold">
                                     seqs_total
                                </div>
                            </div>
                            </Table.Cell>
                            <Table.Cell key={`seqs_merged`}>
                            <div className="flex items-center justify-center space-x-3">
                                <div className=" flex items-center justify-center text-xs font-bold">
                                     seqs_merged
                                </div>
                            </div>
                            </Table.Cell>
                            <Table.Cell key={`seqs_trimmed_fwd`}>
                            <div className="flex items-center justify-center space-x-3">
                                <div className="flex items-center justify-center text-xs font-bold">
                                    seqs_trimmed_fwd
                                </div>
                            </div>
                            </Table.Cell>
                            <Table.Cell key={`seqs_trimmed_rev`}>
                            <div className="flex items-center justify-center space-x-3">
                                <div className="flex items-center justify-center text-xs font-bold">
                                    seqs_trimmed_rev
                                </div>
                            </div>
                            </Table.Cell>
                            <Table.Cell key={`seqs_quality_filtered`}>
                            <div className="flex items-center justify-center space-x-3">
                                <div className="flex items-center justify-center text-xs font-bold">
                                    seqs_quality_filtered
                                </div>
                            </div>
                            </Table.Cell>
                        </Table.Row>

                        {
                            sortedData?.map((item,index) => (
                                <Table.Row key={index+1}>
                                    <Table.Cell key={`${index+1}_customer_name_search`}>
                                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center" data-tooltip-id="my-tooltip" data-tooltip-content={item.customer_sampleID}  data-tooltip-place="top">
                                            {item.customer_sampleID}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell key={`${index+1}_customer_name_search`}>
                                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center" >
                                            {item.type}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell key={`${index+1}_forward_primer_box`}>
                                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center" data-tooltip-id="my-tooltip" data-tooltip-content={item.forward_primer}  data-tooltip-place="top">
                                            {item.forward_primer}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell key={`${index+1}_reverse_primer_box`}>
                                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center" data-tooltip-id="my-tooltip" data-tooltip-content={item.reverse_primer}  data-tooltip-place="top">
                                            {item.reverse_primer}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell key={`${index+1}_sequenceCountTotal`}>
                                    <div className="flex items-center justify-center space-x-3">
                                        <div className="px-3 py-1 bg-blue-300 flex items-center justify-center text-sm">
                                            {(item.sequenceCountTotal/100).toLocaleString('de-DE')}
                                        </div>
                                    </div>
                                    </Table.Cell>
                                    <Table.Cell key={`${index+1}_sequenceCountMerged`}>
                                    <div className="flex items-center justify-center space-x-3">
                                        <div className="px-3 py-1 bg-yellow-300 flex items-center justify-center text-sm">
                                            {(item.sequenceCountMerged/100).toLocaleString('de-DE')}
                                        </div>
                                    </div>
                                    </Table.Cell>
                                    <Table.Cell key={`${index+1}_sequenceCountForwardTrimmed`}>
                                    <div className="flex items-center justify-center space-x-3">
                                        <div className="px-3 py-1 bg-red-300 flex items-center justify-center text-sm">
                                            {(item.sequenceCountForwardTrimmed/100).toLocaleString('de-DE')}
                                        </div>
                                    </div>
                                    </Table.Cell>
                                    <Table.Cell key={`${index+1}_sequenceCountReverseTrimmed`}>
                                    <div className="flex items-center justify-center space-x-3">
                                        <div className="px-3 py-1 bg-red-300 flex items-center justify-center text-sm">
                                            {(item.sequenceCountReverseTrimmed/100).toLocaleString('de-DE')}
                                        </div>
                                    </div>
                                    </Table.Cell>
                                    <Table.Cell key={`${index+1}_sequenceCountQualityFiltered`}>
                                    <div className="flex items-center justify-center space-x-3">
                                        <div className="px-3 py-1 bg-primary flex items-center justify-center text-sm">
                                            {(item.sequenceCountQualityFiltered/100).toLocaleString('de-DE')}
                                        </div>
                                    </div>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                        </Table.Body>
                    </Table>
                </div>:
                <div>
                    <div className='p-2 font-bold font-base'>
                        <Paragraph>Thank you for your order.</Paragraph>
                    </div>
                    <div className='p-2 font-bold font-base'>
                        <Paragraph>We will get back to you soon by email.</Paragraph>
                        <Paragraph>Please check the email account of the email address you provided.</Paragraph>
                    </div>
                </div>
                }
                
                <Spacer/>
            </Box>
            <CancelModal visibileModal$={onCancel$}></CancelModal>
            <Toast messageInterval={5000} message$={message$}></Toast>
            <ContactModal visibileModal$={onContact$} onTrigger$={onContactSent$}></ContactModal>
            <Tooltip id="my-tooltip" />
        </div>
    );
}