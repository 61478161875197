/* eslint-disable jsx-a11y/anchor-is-valid */
import Actions from 'Flux'
import { authenticationSlice } from 'Flux/Slice/Authentication/AuthenticationSlice'
import { useInteraction } from "Framework/View/Hooks/useInteraction";
import { Translation } from 'Translation/Translation';
import { Icon } from 'View/Common';
import { PropsWithChildren, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

export interface IMenuProps<T> extends PropsWithChildren { }

export const Menu = <T,>({ children }: IMenuProps<T>) => {
    //! DEPENDENCIES
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    //! INTERACTION
    const onNavigate$ = useInteraction<string>()
    const onLogout$ = useInteraction<void>()

    const [open, setOpen] = useState<boolean>(false);

    //! EFFECTS
    useEffect(() => {
        const onNavigate$$ = onNavigate$.subscribe((page) => {
            navigate(page)
        })
        return () => onNavigate$$.unsubscribe()
    }, [onNavigate$, navigate])

    useEffect(() => {
        const onLogout$$ = onLogout$.subscribe(() => {
            dispatch(Actions[authenticationSlice.name].logout({}))
        })
        return () => onLogout$$.unsubscribe()
    }, [onLogout$, dispatch])

    //! HELPERS
    const hideSidebar = () => ['/', '/register'].includes(location.pathname) || location.pathname.match('email-confirm')

    return (
        <div className='h-screen sticky top-0'>
            {hideSidebar() ? null : (
                <div className="h-screen w-20 bg-gray-100 duration-300 hover:w-72 p-5 pt-8 " onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                    <ul className='pt-1'>
                        <li className='text-black-300 text-base flex items-center gap-x-4 cursor-pointer hover:bg-gray-300 rounded-md p-2 mb-2' onClick={() => onNavigate$.next('/home')}>
                            <Icon type='home-icon' className='w-8 h-8'></Icon>
                            <span className={`${!open ? 'hidden' : 'duration-300'}`}>Dashboard</span>
                        </li>
                        <li className='text-black-300 text-base flex items-center gap-x-4 cursor-pointer hover:bg-gray-300 rounded-md p-2 mb-2 whitespace-nowrap' onClick={() => onNavigate$.next('/order_management')}>
                            <Icon type='clipboard-icon' className='w-8 h-8'></Icon>
                            <span className={`${!open ? 'hidden' : 'duration-300'}`}>Order Management</span>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    )
}