import { TranslationCreateOrderPage } from '../../View/Page/HomePage/01-CreateOrderPage/01CreateOrderPage.translation'

export const en_US = {
  ...TranslationCreateOrderPage['en_US'],
  common: {
    brandCompany: 'spcfy GmbH',
    brandSlogan: 'Make nature’s diversity count since 2019',
    confirm: 'Confirm',
  },
  acceptInvitation: {
    title: 'Welcome To the spcfy platform',
    welcomeText1: 'You have been invited to join the spcfy platform. Please enter your password to complete the registration progress.',
    welcomeText2: 'Later you can login with your email address and the password you have chosen, to access your metabarcoding data.',
    passwordLabel: 'Enter your new password',
  },
  user: {
    password: 'Password',
    email: 'Email',
    login: 'Log In',
    welcomeText: 'Please use your eMail and password to login, to start processing your DNA metabarcoding data',
    forgotPassword: 'Forgot your password?'
  },
  home: {
    next: 'Next',
    cancel: 'Cancel',
    addDataset: 'Add dataset',
  },
  changePassword:{
    title:'Update password',
    welcomeText:'We will send you an email to verify your account. You can enter a new password after clicking the link in this email. This link is valid for 30 minutes.',
    confirm:'Confirm'
  },
  changePasswordConfirm:{
    welcomeText:'Nearly done! Enter your new password and hit submit.',
    submit:'Submit'
  }
}