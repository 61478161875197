import { filter, mergeMap, of } from 'rxjs'
import { catchError, retryWhen, withLatestFrom } from 'rxjs/operators'
import { refreshAccessTokenAndRetry } from '../../../../Framework/Epic/RefreshAccessToken'
import { serverIsNotAvailable } from '../../../../Framework/Epic/ServerNotAvailable'
import { Epic } from '../../../index'
import { orderSlice } from '../../../Slice/Order/OrderSlice'

export const UpdateSampleEpic: Epic = (
  action$, state$, {
    [orderSlice.name]: order, OrderRepository,
  }) =>
  action$.pipe(
    filter(order.updateSampleRequest.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      OrderRepository.updateSample(action.payload, state.authentication.user).pipe(
        mergeMap((response) => of(order.updateSampleSuccess(response))),
        retryWhen(serverIsNotAvailable()),
        catchError(
          refreshAccessTokenAndRetry({
              action$,
              store: state,
              requestAction: action,
              failureActionCreator: order.updateSampleFailure,
            },
          ),
        ),
      ),
    ),
  )