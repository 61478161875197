import Actions, { useDispatch } from 'Flux'
import {
  useGetCurrentStep,
  useGetDatasetSelector,
  useGetLatestDatasetSelector,
  useGetOrderSelector,
  useGetUploadStatus,
} from 'Flux/Selector'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'
import { ValidationError } from 'Framework/Error/ValidationError'
import { IResult } from 'Framework/Model/IResult'
import { useProperty } from 'Framework/View'
import { useInteraction } from 'Framework/View/Hooks/useInteraction'
import type { IDataSet, IUploadNegativeControl, IUploadSample } from 'Model'
import { useEffect, useRef, useState } from 'react'
import { scan, Subject } from 'rxjs'
import { Translation } from 'Translation/Translation'
import { Box, Button, FileInput, FormControl, Icon, Label, Messagebox, Paragraph, Stepper, Toast, Tooltip } from 'View/Common'
import { Spacer } from 'View/Common/Layout/Spacer'
import { STEPS } from '../HomePage'
import { CancelModal } from '../Modals/CancelModal'
import { Heading2 } from 'View/Common/Typography/Heading2'
import { ContactModal } from '../Modals/ContactModal'

const checkIsFileEndingCorrect = (files: File[]) => {
  return files.every((file) => {
    return file.name.includes('.gz') 
  })
}

const checkPairs = (files: File[]): IResult<(IUploadSample | IUploadNegativeControl)[]> => {
  const fileMap = new Map<string, File>()

  const isPairable = files.every((file) => {
    if (fileMap.has(file.name)) {
      return false
    }

    fileMap.set(file.name, file)
    return file.name.match(/_R1|_R2/g)
  })

  if (!isPairable) {
    return { error: new ValidationError(), value: [] }
  }

  return files.reduce((acc, file) => {
    const isForward = file.name.match(/_R1/g)
    if (isForward) {
      const reverseFile = fileMap.get(file.name.replace('_R1', '_R2'))

      if (!reverseFile) {
        return { error: new ValidationError(), value: acc.value }
      }

      acc.value = [...acc.value || [], { forwardFile: file, reverseFile }]
    }

    const forwardFile = fileMap.get(file.name.replace('_R2', '_R1'))
    if (!forwardFile) {
      return { error: new ValidationError(), value: acc.value }
    }

    return acc
  }, { value: [] } as IResult<(IUploadSample | IUploadNegativeControl)[]>)
}

const checkFileNamesAreUnique = (datasets: Partial<IDataSet>[], files: File[]): boolean => {
  const addedFileNames = new Set<string>()

  for (const dataset of datasets) {
    for (const sample of dataset.samples || []) {
      addedFileNames.add(sample.forwardFile.originalFileName)
      addedFileNames.add(sample.reverseFile.originalFileName)
    }

    for (const negativeControl of dataset.negativeControls || []) {
      addedFileNames.add(negativeControl.forwardFile.originalFileName)
      addedFileNames.add(negativeControl.reverseFile.originalFileName)
    }
  }

  return !files.some((file) => addedFileNames.has(file.name))
}

const currentFileNameCheck = (currentSampleFiles: any[], currentNegativeControlFiles: any[]) => {
  const result = currentSampleFiles.filter(file => currentNegativeControlFiles.some(({
                                                                                       forwardFile,
                                                                                       reverseFile,
                                                                                     }) => file.forwardFile.name === forwardFile.name && file.reverseFile.name === reverseFile.name))

  if (result.length > 0) {
    return false
  } else {
    return true
  }
}

export const UploadDatasetPage = () => {

  const { translate } = Translation
  const dispatch = useDispatch()

  const currentStep = useGetCurrentStep()
  const dataset = useGetLatestDatasetSelector()
  const datasets = useGetDatasetSelector()
  const order = useGetOrderSelector()


  const [sampleCount, setSampleCount] = useState<number>(0)
  const [negativeControlsCount, setNegativeControlsCount] = useState<number>(0)
  const [sampleFilesPair, setSampleFilesPair] = useState<any>()
  const [negativeControlPairs, setNegativeControlPairs] = useState<any>()
  const [allowNextPage, SetAllowNextPage] = useState<boolean>(false)
  const [sampleFileError, setSampleFileError] = useState<boolean>(true)

  const sampleFiles$ = useProperty<File[]>([])
  const negativeControl$ = useProperty<File[]>([])
  const message$ = useRef(new Subject<{ type: 'success' | 'error' | 'warning' | 'info', message: string }>()).current
  const messagebox$ = useRef(new Subject<{ type: 'success' | 'error', message: string }>()).current

  const onNext$ = useInteraction<void>()
  const onCancel$ = useInteraction<void>()
  const onDatasetAdd$ = useInteraction<void>()
  const onContact$ = useInteraction<void>()
  const onContactSent$ = useInteraction<void>()

  useEffect(() => {
    const onNext$$ = onNext$.subscribe(() => {
      if (sampleFiles$.value.length === 0) {
        allowNextPage && dispatch(
          Actions[orderSlice.name].stepNext(),
        )
        !allowNextPage && setSampleFileError(false)
        if (!allowNextPage) {
          message$.next({ type: 'error', message: 'You have to enter at least one dataset' })
        }
      } else {
        onDatasetAdd$.next()
        dispatch(
          Actions[orderSlice.name].stepNext(),
        )
      }
    })
    return () => onNext$$.unsubscribe()
  }, [onNext$, dispatch, onDatasetAdd$, allowNextPage])

  useEffect(() => {
    const onAdd$$ = onDatasetAdd$.subscribe(async () => {
      const isUnique = currentFileNameCheck(sampleFilesPair, negativeControlPairs)
      if (isUnique) {
        if (sampleFiles$.value.length >= 2) {
          setSampleFileError(true)
          sampleFilesPair.forEach((element: IUploadSample) => {
            dispatch(Actions[orderSlice.name].uploadSampleRequest({
              datasetId: dataset.id!,
              forwardFile: element.forwardFile,
              reverseFile: element.reverseFile,
            }))
          })
          negativeControlPairs.forEach((element: IUploadSample) => {
            dispatch(Actions[orderSlice.name].uploadNegativeControlRequest({
              datasetId: dataset.id!,
              forwardFile: element.forwardFile,
              reverseFile: element.reverseFile,
            }))
          })
          negativeControlPairs.length > 0 && messagebox$.next({
            type: 'success',
            message: (sampleFilesPair.length * 2) + ' sample files and ' + (negativeControlPairs.length * 2) + ' negative controls have been successfully uploaded to dataset',
          })
          negativeControlPairs.length === 0 && messagebox$.next({
            type: 'success',
            message: (sampleFilesPair.length * 2) + ' sample files have been successfully uploaded to dataset',
          })
          dispatch(
            Actions[orderSlice.name].createDatasetRequest({ orderId: order?.id }),
          )
          sampleFiles$.next([])
          negativeControl$.next([])
          setSampleFilesPair([])
          setNegativeControlPairs([])
          SetAllowNextPage(true)
          message$.next({
            type: 'success',
            message: 'You can now continue uploading another batch of sample and negative control samples to your next dataset or go to the next step by clicking on "Next".',
          })
        } else {
          setSampleFileError(false)
          message$.next({ type: 'error', message: 'No sample files are uploaded' })
        }
      } else {
        sampleFiles$.next([])
        negativeControl$.next([])
        setSampleFilesPair([])
        setNegativeControlPairs([])
        message$.next({ type: 'error', message: 'File names must be unique' })
      }
    })

    return () => onAdd$$.unsubscribe()
  }, [onDatasetAdd$, dataset, sampleFilesPair, negativeControlPairs, dispatch])

  useEffect(() => {
    const subscription = sampleFiles$
      .pipe(
        scan((acc, value) => value.length ? [...acc, ...value] : []),
      )
      .subscribe((files: File[]) => {
        try {
          const isFileEndingCorrect = checkIsFileEndingCorrect(files)
          if (!isFileEndingCorrect) {
            setSampleCount(0)
            sampleFiles$.next([])
            message$.next({ type: 'error', message: 'Only .fastq.gz files are allowed' })
            return
          }

          const isUnique = checkFileNamesAreUnique(datasets, files)
          if (!isUnique) {
            setSampleCount(0)
            sampleFiles$.next([])
            message$.next({ type: 'error', message: 'File names must be unique' })
            return
          }

          const result = checkPairs(files)
          if (result.error) {
            setSampleCount(0)
            sampleFiles$.next([])
            message$.next({ type: 'error', message: 'Please upload files in pairs' })
            return
          }

          setSampleFilesPair(result.value)
          setSampleCount(files.length)
        } catch (error) {
          console.error(error)
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [message$, datasets, sampleFiles$])

  useEffect(() => {
    const subscription = negativeControl$
      .pipe(
        scan((acc, value) => value.length ? [...acc, ...value] : []),
      )
      .subscribe((files: File[]) => {
        try {
          const isFileEndingCorrect = checkIsFileEndingCorrect(files)
          if (!isFileEndingCorrect) {
            setNegativeControlsCount(0)
            negativeControl$.next([])
            message$.next({ type: 'error', message: 'Only .fastq.gz files are allowed' })
            return
          }

          const isUnique = checkFileNamesAreUnique(datasets, files)
          if (!isUnique) {
            setNegativeControlsCount(0)
            negativeControl$.next([])
            message$.next({ type: 'error', message: 'File names must be unique' })
            return
          }
          const result = checkPairs(files)
          if (result.error) {
            setNegativeControlsCount(0)
            negativeControl$.next([])
            message$.next({ type: 'error', message: 'Please upload files in pairs' })
            return
          }

          setNegativeControlPairs(result.value)
          setNegativeControlsCount(files.length)
        } catch (error) {
          console.error(error)
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [datasets, message$, negativeControl$, sampleFiles$])


  useEffect(() => {
    if ('id' in order) {
      dispatch(
        Actions[orderSlice.name].createDatasetRequest({ orderId: order.id }),
      )
    }
  }, [order, dispatch])

  useEffect(() => {

    const subscription = onContactSent$.subscribe(() => {
      message$.next({
        type: 'success',
        message: 'Your message has been successfully sent',
      })
    })
    return () => subscription.unsubscribe()

  }, [onContactSent$])

  return (
    <div>
      <Box
        action={
          <>
            <Button variant='primary' onClick$={onNext$}>{translate('home:next')}</Button>
            <Button variant='cancel' onClick$={onCancel$}>{translate('home:back')}</Button>
          </>
        }
      >
        <Stepper steps={STEPS} currentStep={currentStep} />
        <Spacer />
        <div className='flex justify-end'>
          <Button variant='contact' onClick$={onContact$}>Contact</Button>
        </div>
        <div className='flex justify-center items-center'>
          <Heading2>Order name: {order.name}</Heading2>
        </div>
        <div className='text-center'>
          <Paragraph>Please upload your demultiplexed sample files and negative controls if available. If you have data from multiple PCR
            plates, please add it to another dataset.</Paragraph>
        </div>
        <div className='font-bold text-center'>
          <Paragraph>spcfy only allows demultiplexed, paired-end Illumina files.</Paragraph>
        </div>
        <div>
          <FormControl>
            <Label valid={sampleFileError}>Sample Files: *</Label>
            <div className='flex'>
              <FileInput
                onChange$={sampleFiles$}
                onReset$={onDatasetAdd$}
                isMultiple={true}
                fileCounter={sampleCount}
                fileAccept='.gz'
                fileType='.fastq.gz'
              />
              <Tooltip
                tooltip='Please upload both FastQ file pairs. The current version of spcfy only support standards, de-multiplexed, paired-end sequencing data. Therefore, for each sample, you need to upload both the R1 and R2 file. Your data should look like this. MySampleNameWithoutUnderscores_S1_L001_R1_001.fastq.gz and MySampleNameWithoutUnderscores_S1_L001_R2_001.fastq.gz'>
                <Icon type='information-circle-icon' className='h-8 w-8'></Icon>
              </Tooltip>
            </div>
          </FormControl>
          <FormControl>
            <Label valid={true}>Negative Controls:</Label>
            <div className='flex'>
              <FileInput
                onChange$={negativeControl$}
                onReset$={onDatasetAdd$}
                isMultiple={true}
                fileAccept='.gz'
                fileType='.fastq.gz'
                fileCounter={negativeControlsCount}
              />
              <Tooltip
                tooltip='Please upload both FastQ file pairs. The current version of spcfy only support standards, de-multiplexed, paired-end sequencing data. Therefore, for each sample, you need to upload both the R1 and R2 file. Your data should look like this. MySampleNameWithoutUnderscores_S1_L001_R1_001.fastq.gz and MySampleNameWithoutUnderscores_S1_L001_R2_001.fastq.gz'>
                <Icon type='information-circle-icon' className='h-8 w-8'></Icon>
              </Tooltip>
            </div>
          </FormControl>
          <div className='flex justify-end'>
            <div className='flex'>
              <Button onClick$={onDatasetAdd$}>{translate('home:addDataset')}({(datasets.length - 1)}) </Button>
              <Tooltip
                tooltip='If your lab protocols include negative control samples (NCs) along with biological samples, you have the option of associating both groups of samples in this step (i.e. spcfy considers these samples as one “dataset”). If you have biological samples sequenced on multiple plates with their own corresponding NCs, you have to upload them separately by using the “Add dataset” option.'>
                <Icon type='information-circle-icon' className='h-8 w-8'></Icon>
              </Tooltip>
            </div>
          </div>
          <Messagebox messageInterval={5000} message$={messagebox$}></Messagebox>
          <Spacer />
        </div>
      </Box>
      <CancelModal visibileModal$={onCancel$}></CancelModal>
      <Toast messageInterval={5000} message$={message$}></Toast>
      <ContactModal visibileModal$={onContact$} onTrigger$={onContactSent$}></ContactModal>
    </div>
  )
}