import { useSelector } from 'Flux'
import { State } from 'Flux/Slice'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'

export const useGetAllOrders = () => {
  return {
    orders: useSelector(getAllOrders),
    total : useSelector(getTotalOrders)
  }
}

export const getAllOrders = (state: typeof State) => state[orderSlice.name].orders
export const getTotalOrders = (state: typeof State) => state[orderSlice.name].total