import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { removeRequest } from 'Flux'
import { IUser } from 'Model'

export interface IAuthenticationState {
  user?: IUser;
  openRequests: string[];
}

const initialState: IAuthenticationState = {
  user: undefined,
  openRequests: [],
}

const reducers = {
  loginRequest: (state: Draft<IAuthenticationState>, action: PayloadAction<{ email: string; password: string }>) => {
    state.openRequests.push(action.type)
  },
  loginSuccess: (state: Draft<IAuthenticationState>, action: PayloadAction<{ user: IUser }>) => {
    state.user = action.payload.user
    state.openRequests = removeRequest(state, action)
  },
  loginFailure: (state: Draft<IAuthenticationState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  refreshAccessTokenRequest: (state: Draft<IAuthenticationState>, action: PayloadAction<{ refreshToken: string }>) => {
    state.openRequests.push(action.type)
  },
  refreshAccessTokenSuccess: (state: Draft<IAuthenticationState>, action: PayloadAction<{ user: IUser }>) => {
    state.user = action.payload.user
    state.openRequests = removeRequest(state, action)
  },
  refreshAccessTokenFailure: (state: Draft<IAuthenticationState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  logout: (state: Draft<IAuthenticationState>, action: PayloadAction<{}>) => {
    state.user = undefined
  },
  acceptInvitationRequest: (state: Draft<IAuthenticationState>, action: PayloadAction<{
    invitationId: string,
    password: string
  }>) => {
    state.openRequests.push(action.type)
  },
  acceptInvitationSuccess: (state: Draft<IAuthenticationState>, action: PayloadAction<{}>) => {
    state.openRequests = state.openRequests.filter(request => request !== authenticationSlice.actions.acceptInvitationRequest.type)
  },
  acceptInvitationFailure: (state: Draft<IAuthenticationState>, action: PayloadAction<{}>) => {
    state.openRequests = state.openRequests.filter(request => request !== authenticationSlice.actions.acceptInvitationRequest.type)
  },
  changePasswordRequest: (state: Draft<IAuthenticationState>, action: PayloadAction<{ email: string }>) => {
    state.openRequests.push(action.type)
  },
  changePasswordSuccess: (state: Draft<IAuthenticationState>, action: PayloadAction<{}>) => {
    state.openRequests = removeRequest(state, action)
  },
  changePasswordFailure: (state: Draft<IAuthenticationState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  changePasswordConfirmRequest: (state: Draft<IAuthenticationState>, action: PayloadAction<{ password: string; token: string }>) => {
    state.openRequests.push(action.type)
  },
  changePasswordConfirmSuccess: (state: Draft<IAuthenticationState>, action: PayloadAction<{}>) => {
    state.openRequests = removeRequest(state, action)
  },
  changePasswordConfirmFailure: (state: Draft<IAuthenticationState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  reset: () => {
    return initialState
  },
}

export const authenticationSlice = createSlice<IAuthenticationState, typeof reducers, 'authentication'>({
  name: 'authentication',
  initialState,
  reducers,
})
