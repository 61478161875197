import { useGetAuthenticatedUser, useGetCurrentStep } from 'Flux/Selector'
import React, { useEffect, useRef, useState } from 'react'
import { BehaviorSubject, concat, Subject } from 'rxjs'
import Actions, { useDispatch } from '../../../../Flux'
import { useInteraction } from '../../../../Framework/View/Hooks/useInteraction'
import { Translation } from '../../../../Translation/Translation'
import { Box, Button, FormControl, Input, Label, Paragraph, Select, Stepper, Toast } from '../../../Common'
import { Spacer } from '../../../Common/Layout/Spacer'
import { STEPS } from '../HomePage'
import { ContactModal } from '../Modals/ContactModal'


const taxaOptions = [
  {
    value: 'co1_animal',
    label: 'CO1 - Animal',
    disabled: false,
  }, {
    value: 'co1_animal_ce',
    label: 'CO1 - Animal - Central Europe',
    disabled: false,
  }, {
    value: '_16s_bacteria',
    label: '16s - Bacteria',
    disabled: false,
  }, {
    value: 'fits2_fungal',
    label: 'FITS2 - Fungal',
    disabled: false,
  }, {
    value: 'pits2_plant',
    label: 'PITS2 - Plant',
    disabled: false,
  }, {
    value: '_12s_bacteria',
    label: '12s - Bacteria',
    disabled: false,
  }, {
    value: 'custom_primer',
    label: 'Custom primer',
    disabled: true,
  },
]

const singletonOptions = [
  {
    value: "false",
    label: "COMPONENT:CREATE_ORDER:SINGLETON_ALLOWED_NO",
    disabled: false,
  }, {
    value: "true",
    label: "COMPONENT:CREATE_ORDER:SINGLETON_ALLOWED_YES",
    disabled: false,
  }
]


export const CreateOrderPage = () => {
  const { translate } = Translation
  const dispatch = useDispatch()

  const currentStep = useGetCurrentStep()
  const authenticatedUser = useGetAuthenticatedUser()

  const orderName$ = useRef(new BehaviorSubject('')).current
  const taxon$ = useRef(new BehaviorSubject('')).current
  const singleton$ = useRef(new BehaviorSubject("false")).current
  const message$ = useRef(new Subject<{ type: 'success' | 'error' | 'warning' | 'info', message: string }>()).current

  const [orderNameError, setOrderNameError] = useState<boolean>(true)

  const onNext$ = useInteraction<void>()
  const onCancel$ = useInteraction<void>()
  const onContact$ = useInteraction<void>()
  const onContactSent$ = useInteraction<void>()

  useEffect(() => {
    const subscription = onNext$.subscribe(() => {
      const orderName = orderName$.value.trim()
      const taxon = taxon$.value;
      const singleton = singleton$.value;

      if (orderName) {
        setOrderNameError(true)
        dispatch(
          Actions.order.createOrderRequest({
            name: orderName,
            targetTaxa: taxon,
            allowSingletons: singleton === "true",
          }),
        )
      } else {
        setOrderNameError(false)
        message$.next({
          type: 'error',
          message: translate('COMPONENT:CREATE_ORDER:ORDER_NAME_EMPTY_ERROR_MESSAGE'),
        })
      }
    })

    return () => subscription.unsubscribe()
  }, [dispatch, message$, onNext$, orderName$, translate])

  useEffect(() => {
    const subscription = concat(onCancel$, onNext$).subscribe(() => {
      orderName$.next('')
    })
    return () => subscription.unsubscribe()
  }, [onCancel$, onNext$, orderName$])

  useEffect(() => {

    const subscription = onContactSent$.subscribe(() => {
      message$.next({
        type: 'success',
        message: 'Your message has been successfully sent',
      })
    })
    return () => subscription.unsubscribe()

  }, [onContactSent$])

  return (
    <div>
      <Box
        action={<Button variant='primary' onClick$={onNext$}>{translate('home:next')}</Button>}
      >
        <Stepper steps={STEPS} currentStep={currentStep} />
        <Spacer />
        <div className='flex justify-end'>
          <Button variant='contact' onClick$={onContact$}>Contact</Button>
        </div>
        <div className='text-center'>
          <Paragraph>Hi, {authenticatedUser?.firstName} welcome to spcfy! Please assign an order name in order to continue uploading your
            files.</Paragraph>
        </div>
        <FormControl>
          <Label valid={orderNameError}>{translate('COMPONENT:CREATE_ORDER:ORDER_NAME_LABEL')}</Label>
          <Input type='text' onChange$={orderName$}
                 placeholder={translate('COMPONENT:CREATE_ORDER:ORDER_NAME_PLACEHOLDER')} />
        </FormControl>
        <FormControl>
          <Label valid={true}>{translate('COMPONENT:CREATE_ORDER:SINGLETON_REMOVAL')}</Label>
          <Select
            onChange$={singleton$}
            options={singletonOptions.map(x => {return {...x, label: translate(x.label)}})}
            initialValue='false'
          />
        </FormControl>
        <FormControl
          tooltip={translate('The MVP beta version of spcfy only supports animal CO1 FASTQ files for the bioinformatic pipeline and species recovery.')}
        >
          <Label valid={true}>{translate('COMPONENT:CREATE_ORDER:TARGET_TAXON')}</Label>
          <Select
            onChange$={taxon$}
            options={taxaOptions}
            initialValue='co1_animal'
          />
        </FormControl>
        <Spacer />
      </Box>
      <Toast messageInterval={5000} message$={message$}></Toast>
      <ContactModal visibileModal$={onContact$} onTrigger$={onContactSent$}></ContactModal>
    </div>
  )
}
