import { useGetAuthenticatedUser, useGetCurrentStep } from 'Flux/Selector'
import { Navigate } from 'react-router-dom'
import { PageLayout } from '../../Layout/PageLayout/PageLayout'
import { CreateOrderPage } from './01-CreateOrderPage/01CreateOrderPage'
import { UploadDatasetPage } from './02-UploadDatasetPage/02-UploadDatasetPage'
import { UploadPrimerPage } from './03-UploadPrimerPage/UploadPrimerPage'
import { OrderReportPage } from './04-OrderReportPage/04-OrderReportPage'
import { QualityCheckPage } from './05-QualityCheckPage/05-QualityCheckPage'


export const STEPS = [
  'Start\n Order',
  'Upload\n Dataset(s)',
  'Upload\n Primer',
  'Order\n Report',
  'Check\n Quality'
]

export const HomePage = () => {

  const authenticatedUser = useGetAuthenticatedUser()
  const currentStep = useGetCurrentStep()

  if (!authenticatedUser) {
    return <Navigate to={'/'} />
  }

  return (
    <PageLayout>
      {currentStep === 0
        ? <CreateOrderPage />
        : currentStep === 1 ?
          <UploadDatasetPage /> :
          currentStep === 2 ?
            <UploadPrimerPage /> :
          currentStep === 3 ?
            <OrderReportPage /> :
          currentStep === 4 ?
            <QualityCheckPage /> :
            null
      }

    </PageLayout>
  )
}
