import { Epic } from 'Flux/index'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'
import { refreshAccessTokenAndRetry } from 'Framework/Epic/RefreshAccessToken'
import { serverIsNotAvailable } from 'Framework/Epic/ServerNotAvailable'
import { EMPTY, filter, from, mergeMap } from 'rxjs'
import { catchError, retryWhen, withLatestFrom } from 'rxjs/operators'


export const UploadNegativeControlEpic: Epic = (
  action$,
  state$,
  { [orderSlice.name]: order, OrderRepository },
) =>
  action$.pipe(
    filter(order.uploadNegativeControlRequest.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      OrderRepository.uploadNegativeControls(action.payload as any, state.authentication.user).pipe(
        // mergeMap((response) => of(order.uploadSampleSuccess(response))),
        mergeMap((response) => {
          if (response.type === 'upload_loadstart') {
            return from([
              order.setUploadStatus({
                fileName: action.payload.forwardFile.name,
                percent: 0,
              }),
              order.setUploadStatus({
                fileName: action.payload.reverseFile.name,
                percent: 0,
              }),
            ])
          }
          if (response.type === 'upload_progress') {
            return from([
              order.setUploadStatus({
                fileName: action.payload.forwardFile.name,
                percent: Math.round((response.originalEvent.loaded * 100) / response.originalEvent.total),
              }),
              order.setUploadStatus({
                fileName: action.payload.reverseFile.name,
                percent: Math.round((response.originalEvent.loaded * 100) / response.originalEvent.total),
              }),
            ])
          }
          if (response.type === 'upload_load') {
            return from([
              order.setUploadStatus({
                fileName: action.payload.forwardFile.name,
                percent: 100,
              }),
              order.setUploadStatus({
                fileName: action.payload.reverseFile.name,
                percent: 100,
              }),
            ])
          }
          if (response.status === 200) {
            return from([
              order.uploadNegativeControlSuccess({
                resource: response.response.resource,
              }),
            ])
          }
          return EMPTY
        }),
        retryWhen(serverIsNotAvailable()),
        catchError(
          refreshAccessTokenAndRetry({
            action$,
            store: state,
            requestAction: action,
            failureActionCreator: order.uploadNegativeControlFailure,
          }),
        ),
      ),
    ),
  )