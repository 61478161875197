import { parseContent } from '../../../../Translation'

const SEPARATOR = ':'
const BASE_PATH = ['PAGE', 'CREATE_ORDER_PAGE']

const CONTENT = {
  COMPONENT: {
    CREATE_ORDER: {
      ORDER_NAME_LABEL: {
        PATH: [...BASE_PATH, 'ORDER_NAME_LABEL'].join(SEPARATOR),
        de_DE: 'Name der Bestellung: *',
        en_US: 'Name your order: *',
      },
      ORDER_NAME_PLACEHOLDER: {
        PATH: [...BASE_PATH, 'ORDER_NAME'].join(SEPARATOR),
        de_DE: 'Bestellname',
        en_US: 'Order name',
      },
      ORDER_NAME_EMPTY_ERROR_MESSAGE: {
        PATH: [...BASE_PATH, 'ORDER_NAME_EMPTY_ERROR_MESSAGE'].join(SEPARATOR),
        de_DE: 'Bitte geben Sie einen Bestellnamen ein.',
        en_US: 'Please enter an order name.',
      },
      TARGET_TAXON: {
        PATH: [...BASE_PATH, 'TARGET_TAXON'].join(SEPARATOR),
        de_DE: 'Ziel-Taxon',
        en_US: 'Target taxon: *',
      },
      TARGET_TAXON_TOOLTIP: {
        PATH: [...BASE_PATH, 'TARGET_TAXON_TOOLTIP'].join(SEPARATOR),
        de_DE: 'Die MVP beta Version von spcfy unterstützt nur Tier CO1 FASTQ Dateien für die bioinformatische Pipeline und die Artenbestimmung.',
        en_US: 'The MVP beta version of spcfy only supports animal CO1 FASTQ files for the bioinformatic pipeline and species recovery.',
      },
      SINGLETON_REMOVAL: {
        PATH: [...BASE_PATH, 'SINGLETON_REMOVAL'].join(SEPARATOR),
        de_DE: 'Sollen Singleton Matches im Ergebnis auftauchen?',
        en_US: 'Should Singleton Matches be present in the result?'
      },
      SINGLETON_ALLOWED_YES: {
        PATH: [...BASE_PATH, 'SINGLETON_ALLOWED_YES'].join(SEPARATOR),
        de_DE: 'Ja',
        en_US: 'Yes'
      },
      SINGLETON_ALLOWED_NO: {
        PATH: [...BASE_PATH, 'SINGLETON_ALLOWED_NO'].join(SEPARATOR),
        de_DE: 'Nein',
        en_US: 'No'
      }
    },
  },
}

export const TranslationCreateOrderPage = parseContent(CONTENT)