import { filter, mergeMap, of } from 'rxjs'
import { catchError, retryWhen, withLatestFrom } from 'rxjs/operators'
import { refreshAccessTokenAndRetry } from '../../../../Framework/Epic/RefreshAccessToken'
import { serverIsNotAvailable } from '../../../../Framework/Epic/ServerNotAvailable'
import { Epic } from '../../../index'
import { Actions } from '../../../Slice'

export const GetPrimerEpic: Epic = (action$, state$, { order, PrimerRepository }) => action$.pipe(
  filter(Actions.order.getPrimerRequest.match),
  withLatestFrom(state$),
  mergeMap(([action, state]) =>
    PrimerRepository.getPrimer(action.payload, state.authentication.user).pipe(
      mergeMap((response) => of(order.getPrimerSuccess(response))),
      retryWhen(serverIsNotAvailable()),
      catchError(
        refreshAccessTokenAndRetry({
          action$,
          store: state,
          requestAction: action,
          failureActionCreator: order.getPrimerFailure,
        }),
      ),
    ),
  ),
)