import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LoginPage } from 'View/Page/LoginPage/LoginPage'
import { Store } from './Flux'
import { AcceptInvitationPage } from './View/Page/AcceptInvitationPage/AcceptInvitationPage'
import { HomePage } from './View/Page/HomePage/HomePage'
import { ChangePasswordPage } from 'View/Page/AccountRecovery/ChangePasswordPage/ChangePasswordPage'
import { ChangePasswordConfirmPage } from 'View/Page/AccountRecovery/ChangePasswordConfirmPage/ChangePasswordConfirmPage'
import { OrderManagementPage } from 'View/Page/OrderManagementPage/OrderManagementPage'
import * as Sentry from "@sentry/react";
import { Environment } from 'environment'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <Provider store={Store}>
      <BrowserRouter>
        <SentryRoutes>
          <Route path={'/'} element={<LoginPage />} />
          <Route path='/home' element={<HomePage />} />
          <Route path='/account-recovery' element={<ChangePasswordPage />} />
          <Route path='/account-recovery/:token' element={<ChangePasswordConfirmPage />} />
          <Route path={'/invitation/accept/:token'} element={<AcceptInvitationPage />} />
          <Route path={'/order_management'} element={<OrderManagementPage />} />
        </SentryRoutes>
      </BrowserRouter>
    </Provider>
  )
}

export default App
