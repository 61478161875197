import { applicationSlice } from './Application/ApplicationSlice'
import { authenticationSlice } from './Authentication/AuthenticationSlice'
import { orderSlice } from './Order/OrderSlice'

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const Slices = [
  applicationSlice,
  authenticationSlice,
  orderSlice,
] as const

export const Actions = {
  [applicationSlice.name]: { ...applicationSlice.actions },
  [authenticationSlice.name]: { ...authenticationSlice.actions },
  [orderSlice.name]: { ...orderSlice.actions },
}

export const State = {
  [applicationSlice.name]: applicationSlice.getInitialState(),
  [authenticationSlice.name]: authenticationSlice.getInitialState(),
  [orderSlice.name]: orderSlice.getInitialState(),
}

export const Reducer = {
  [applicationSlice.name]: applicationSlice.reducer,
  [authenticationSlice.name]: authenticationSlice.reducer,
  [orderSlice.name]: orderSlice.reducer,
}
