import packageJSON from '../../../../package.json'
import { Paragraph } from '../../Common/Typography/Paragraph'
import { Translation } from '../../../Translation/Translation'
import * as Sentry from "@sentry/react"

const version = packageJSON.version

export const Footer = () => {
  const translate = Translation.translate

  async function reportBug() {
    const feedbackForm = await Sentry.getFeedback()?.createForm();
    if (feedbackForm) {
      feedbackForm.appendToDom();
      feedbackForm.open();
    } else {
      console.warn("Sentry did not produce a feedback form, if sentry is not configured correctly, this is to be expected")
    }
  }

  return (
    <div>
      <div className='bg-repeat-x bg-body-default'
           style={{ backgroundImage: 'url(\'/forest_transparent.png\')', height: 108, opacity: 1 }}></div>
      <footer className='w-full text-white flex content-center flex-col flex-wrap align-center bg-black'>
        <div className='px-2 sm:px-6 md:px-16 pt-16 m-auto text-center'>
          <svg
            width='50'
            height='50'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            fillRule='evenodd'
            clipRule='evenodd'
            className='inline-block fill-current'
          >
            <path
              d='M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z'></path>
          </svg>
          <Paragraph color='contrast' highlight={'none'}>{translate('common:brandCompany')}</Paragraph>
          <Paragraph color='contrast' highlight={'none'}>{translate('common:brandSlogan')}</Paragraph>
          <Paragraph color='contrast' highlight='none'>Copyright © {new Date().getFullYear()} - All right
            reserved</Paragraph>
          <Paragraph color='contrast' highlight='none'>Version {version}</Paragraph>
        </div>
        <div className='flex flex-row flex-wrap px-2 sm:px-6 md:px-16 py-8 justify-center gap-6'>
          <a href='https://www.linkedin.com/company/spcfy-gmbh' target='_blank' rel={'noreferrer'} className=''>
            <svg style={{ color: '#0A66C2' }} className='w-6 h-6 fill-current' xmlns='http://www.w3.org/2000/svg'
                 viewBox='0 0 448 512'>
              <path
                d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'></path>
            </svg>
          </a>
        </div>
        <div className='flex flex-row justify-center gap-6 text-primary-content underline pb-8'>
          <Paragraph color={'contrast'}>Terms of Use</Paragraph>
          <Paragraph>|</Paragraph>
          <Paragraph color={'contrast'}>Privacy Policy</Paragraph>
          <Paragraph>|</Paragraph>
          <Paragraph color={'contrast'}>Imprint</Paragraph>
          <Paragraph>|</Paragraph>
          <Paragraph color={'contrast'}><span onClick={reportBug}>Report a Bug</span></Paragraph>
        </div>
      </footer>
    </div>
  )
}