import { filter, mergeMap, of } from 'rxjs'
import { catchError, retryWhen, withLatestFrom } from 'rxjs/operators'
import { refreshAccessTokenAndRetry } from '../../../../Framework/Epic/RefreshAccessToken'
import { serverIsNotAvailable } from '../../../../Framework/Epic/ServerNotAvailable'
import { Epic } from '../../../index'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'

export const UpdateNegativeControlEpic: Epic = (action$, state$, {
  [orderSlice.name]: order, OrderRepository,
}) => action$
  .pipe(
    filter(order.updateNegativeControlRequest.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      OrderRepository.updateNegativeControl(action.payload as any, state.authentication.user).pipe(
        mergeMap((response) => of(order.updateNegativeControlSuccess(response))),
        retryWhen(serverIsNotAvailable()),
        catchError(
          refreshAccessTokenAndRetry({
            action$,
            store: state,
            requestAction: action,
            failureActionCreator: order.updateNegativeControlFailure,
          }),
        ),
      ),
    ),
  )