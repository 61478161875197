import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { IMessage } from 'Model'

export interface IApplicationState {
  messages: any;
  openRequests: string[];
}

const initialState: IApplicationState = {
  messages: {},
  openRequests: [],
}

const reducers = {
  showMessage: (state: Draft<IApplicationState>, action: PayloadAction<IMessage>) => {
    state.messages[action.payload.id] = action.payload
  },
  removeMessage: (state: Draft<IApplicationState>, action: PayloadAction<{ id: string }>) => {
    const messages = { ...state.messages }
    delete messages[action.payload.id]
    state.messages = messages
  },
  reset: () => {
    return initialState
  },
}

export const applicationSlice = createSlice<typeof initialState, typeof reducers, 'application'>({
  name: 'application',
  reducers,
  initialState,
})
