import { combineEpics } from 'redux-observable'
import { CreateOrderEpic } from './CreateOrder/CreateOrderEpic'
import { CreateDatasetEpic } from './CreateDataset/CreateDatasetEpic'
import { GetPrimerEpic } from './GetPrimer/GetPrimerEpic'
import { SubmitOrderEpic } from './SubmitOrder/SubmitOrderEpic'
import { UpdateSampleEpic } from './UpdateSample/UpdateSampleEpic'
import { UploadSampleEpic } from './UploadSample/UploadSampleEpic'
import { UploadNegativeControlEpic } from './UploadNegativeControl/UploadNegativeControlEpic'
import { AddPrimerEpic } from './AddPrimer/AddPrimerEpic'
import { SendContactEpic } from './SendContact/SendContactEpic'
import { GetAllDatasetsEpic } from './GetAllDatasets/GetAllDatasetsEpic'
import { UpdateNegativeControlEpic } from './UpdateNegativeControl/UpdateNegativeControlEpic'
import { GetAllOrderEpic } from './GetAllOrder/GetAllOrderEpic'
import { GetDatasetReportEpic } from './GetDatasetReport/GetDatasetReportEpic'
import { GetOrderFilesEpic } from './GetOrderFiles/GetOrderFilesEpic'

export const orderEpics = combineEpics(
  AddPrimerEpic,
  CreateOrderEpic,
  CreateDatasetEpic,
  GetPrimerEpic,
  SubmitOrderEpic,
  UploadNegativeControlEpic,
  UpdateSampleEpic,
  UploadSampleEpic,
  UpdateNegativeControlEpic,
  AddPrimerEpic,
  SendContactEpic,
  GetAllDatasetsEpic,
  GetAllOrderEpic,
  GetDatasetReportEpic,
  GetOrderFilesEpic

)
