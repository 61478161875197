import { filter, from, mergeMap } from 'rxjs'
import { Epic } from '../../../index'
import { Slices } from '../../../Slice'
import { authenticationSlice } from '../../../Slice/Authentication/AuthenticationSlice'

export const logoutEpic: Epic = (action$) => {
  return action$.pipe(
    filter(authenticationSlice.actions.logout.match),
    mergeMap(() => {
      return from(Slices.map((slice) => slice.actions.reset()))
    }),
  )
}