import { Translation } from '../../../Translation/Translation'
import { useEffect } from 'react'
import { useProperty } from '../../../Framework/View'
import { useInteraction } from '../../../Framework/View/Hooks/useInteraction'
import { Box, Button, FormControl, Input, Label, Paragraph } from '../../Common'
import { Spacer } from '../../Common/Layout/Spacer'
import { PageLayout } from '../../Layout/PageLayout/PageLayout'
import Actions, { useDispatch } from 'Flux'
import { authenticationSlice } from 'Flux/Slice/Authentication/AuthenticationSlice'
import { useGetAuthenticatedUser } from 'Flux/Selector'
import { Navigate } from 'react-router-dom'
import { Environment } from 'environment'

export const LoginPage = () => {
  const { translate } = Translation
  const dispatch = useDispatch()

  const authenticatedUser = useGetAuthenticatedUser()

  const password$ = useProperty<string>('')
  const email$ = useProperty<string>('')
  const onSubmit$ = useInteraction<void>()

  useEffect(() => {
    console.log("Environment", Environment);
    const onSubmit$$ = onSubmit$.subscribe(() => {
      dispatch(
        Actions[authenticationSlice.name].loginRequest({
          email: email$.value,
          password: password$.value,
        }),
      )
    })
    return () => onSubmit$$.unsubscribe()
  }, [onSubmit$, password$, email$, dispatch])

  if (authenticatedUser) {
    return <Navigate to={'/home'} />
  }

  return (
    <PageLayout>
      <Box
        title={translate('acceptInvitation:title')}
        action={<Button variant='primary' onClick$={onSubmit$}>{translate('user:login')}</Button>}
      >
        <Spacer>
          <Paragraph>{translate('user:welcomeText')}</Paragraph>
        </Spacer>
        <FormControl>
          <Label>Email</Label>
          <Input type='text' onChange$={email$} placeholder={translate('user:email')} />
        </FormControl>
        <FormControl>
          <Label>Password</Label>
          <Input type='password' onChange$={password$} placeholder={translate('user:password')} />
          <Label>
              <a href="/account-recovery" className="float-right hover:underline">
                    {translate('user:forgotPassword')}
              </a>
          </Label>
        </FormControl>
      </Box>
    </PageLayout>
  )
}