import { useSelector } from 'Flux'
import { State } from 'Flux/Slice'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'
import { IDataSet } from 'Model'

export const useGetDatasetSelector = () => {
  return useSelector(getDataset)
}

export const getDataset = (state: typeof State): IDataSet[] => {
  return Object.values(state[orderSlice.name].dataset)
}
