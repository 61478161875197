import { catchError, filter, ignoreElements, mergeMap, of, tap } from 'rxjs'
import { Epic } from 'Flux'
import { authenticationSlice } from '../../../Slice/Authentication/AuthenticationSlice'

export const AcceptInvitationEpic: Epic = (action$, _, { AuthenticationRepository }) => {
  return action$.pipe(
    filter(action => action.type === authenticationSlice.actions.acceptInvitationRequest.type),
    mergeMap((action) => {
      return AuthenticationRepository.acceptInvitation(action.payload).pipe(
        mergeMap((response) => {
            return of(authenticationSlice.actions.acceptInvitationSuccess(response))

          },
        ),
        catchError((error) => {
          return of(authenticationSlice.actions.acceptInvitationFailure(error))
        }),
      )
    }),
  )
}