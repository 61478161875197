import { Observable } from 'rxjs'
import { Button, Modal } from 'View/Common'
import { useEffect, useState } from 'react'
import { useInteraction } from 'Framework/View/Hooks/useInteraction'
import Actions, { useDispatch } from 'Flux'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'

export interface ICancelModalProps {
  visibileModal$: Observable<void>
}

export const CancelModal = ({ visibileModal$ }: ICancelModalProps) => {

  const [visibleModal, setValue] = useState(false)
  const dispatch = useDispatch()

  const onNo$ = useInteraction<void>()
  const onYes$ = useInteraction<void>()

  useEffect(() => {
    const visibileModal$$ = visibileModal$.subscribe(() => {
      setValue(true)
    })
    return () => visibileModal$$.unsubscribe()
  }, [visibileModal$])

  useEffect(() => {
    const onNo$$ = onNo$.subscribe(() => {
      setValue(false)
    })
    return () => onNo$$.unsubscribe()
  }, [onNo$])

  useEffect(() => {
    const onYes$$ = onYes$.subscribe(() => {
      setValue(false)
      dispatch(
        Actions[orderSlice.name].stepBack(),
      )
    })
    return () => onYes$$.unsubscribe()
  }, [onYes$])


  return (
    <Modal isOpen={visibleModal}>
      <Modal.Body>
        Are you sure you want to go a step back?
      </Modal.Body>
      <Modal.Actions>
        <Button onClick$={onNo$} variant='ghost'>No</Button>
        <Button onClick$={onYes$} variant='primary'>Yes</Button>
      </Modal.Actions>
    </Modal>
  )
}