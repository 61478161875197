import { useGetUploadStatus } from 'Flux/Selector'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Icon, LoadingIndicator, Paragraph } from 'View/Common'
import { Spacer } from 'View/Common/Layout/Spacer'
import {Menu, Navigation } from 'View/Component'
import { Footer } from '../../Component'

interface IProps extends PropsWithChildren {
}

export const maxLength = (length: number, str: string) => {
  if (str.length > length) {
    return str.substring(0, length - 1) + '...'
  }
  return str
}

export const PageLayout = ({ children }: IProps) => {
  const uploadStatus = useGetUploadStatus()
  const [isUploadBoxVisible, setIsUploadBoxVisible] = useState<boolean>(true)

  useEffect(()=>{
    console.log(uploadStatus)
  },[uploadStatus])

  return (
    <>
      <Navigation />
      <main className='bg-body-default w-auto h-full min-h-screen flex flex-row text-font-default'>
        <Menu />
        <div className='w-full'>
        <div className='relative bottom-0 mx-auto my-0 sm:my-6 md:my-16 lg:my-32 max-w-6xl w-full'>
          {children}
        </div>
        {Object.keys(uploadStatus).length > 0 ? (
          <div id='overlay' className='z-50 fixed left-0 top-0 right-0 bottom-0 bg-opacity-70'>
            <div className='w-full h-full flex flex-col justify-center items-center'>
              {/* <p className='text-green-600 text-4xl'>Uploading Data</p>
              <Spacer />
              <p className='text-green-600'>Please Wait ...</p>
              <Spacer /> */}
              {/* <LoadingIndicator /> */}
            </div>
            <div className='fixed bottom-0 right-6 w-80'>
              <div className='w-full flex flex-row justify-end -mb-16 pr-16'>
                <div
                  style={{ background: '#68d793' }}
                  className={`transition-transform w-96 ${!isUploadBoxVisible ? 'h-96':'h-32'} bg-white border rounded-t-md z-10 text-white `}
                >
                  <div className='w-full h-14 bg-success flex flex-row justify-between items-center p-3 rounded-t-md'>
                    <LoadingIndicator />
                    <p className='font-semibold text-sm'>
                      Upload Status (
                      {Math.floor(
                        Object.values(uploadStatus)
                          .filter((v) => v >= 0)
                          .reduce((acc, curr) => (acc === 0 ? curr : (acc + curr) / 2), 0),
                      )}
                      %)
                    </p>
                    <div
                      onClick={() => setIsUploadBoxVisible(!isUploadBoxVisible)}
                      className='w-10 h-10 flex flex-col justify-center items-center cursor-pointer'
                    >
                      {isUploadBoxVisible ? (
                        <Icon type='chevron-down-icon' className='w-5 h-5' />
                      ) : (
                        <Icon type='chevron-up-icon' className='w-5 h-5' />
                      )}
                    </div>
                  </div>
                  {
                    !isUploadBoxVisible && <div className='flex flex-col justify-start h-80 px-3 pt-3 gap-3 overflow-scroll'>
                    {Object.keys(uploadStatus).map((fileName, index) => (
                      <div key={index}
                           className='border border-dotted p-3 border-black rounded-md bg-gray-100 flex flex-row justify-between items-center'>
                        <div>
                          <Paragraph>{maxLength(34, fileName)}</Paragraph>
                          <div className='h-1 w-72 bg-neutral-200 dark:bg-neutral-600'>
                            <div className='h-1 bg-primary' style={{ width: `${uploadStatus[fileName]}%` }}></div>
                          </div>
                          {/* <Progress
                                                className="w-72"
                                                value={uploadStatus[fileName]}
                                                max={100}
                                                color={uploadStatus[fileName] === 100 ? 'success' : uploadStatus[fileName] === -1 ? 'error' : 'primary'}
                                                ></Progress> */}
                        </div>
                        {uploadStatus[fileName] === 100 ? (
                          <div className='rounded-full w-8 h-8 flex flex-col justify-center items-center border bg-success'>
                            <Icon type='check-icon' className={'w-5 h-5 text-white'} />
                          </div>
                        ) : uploadStatus[fileName] === -1 ? (
                          <div className='rounded-full w-8 h-8 flex flex-col justify-center items-center border bg-error'>
                            <Icon type='xicon' className={'w-5 h-5 text-white'} />
                          </div>
                        ) : (
                          <div className='rounded-full w-8 h-8 flex flex-col justify-center items-center'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='w-5 h-5 animate-spin'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    ))}
                    <Spacer />
                  </div> 
                  }

                </div>
              </div>
            </div>
            <div className='flex flex-col justify-start h-80 px-3 pt-3 gap-3 overflow-scroll'>
              {Object.keys(uploadStatus).map((fileName, index) => (
                <div key={index}
                     className='border border-dotted p-3 border-black rounded-md bg-gray-100 flex flex-row justify-between items-center'>
                  <div>
                    <Paragraph>{maxLength(34, fileName)}</Paragraph>
                    <div className='h-1 w-72 bg-neutral-200 dark:bg-neutral-600'>
                      <div className='h-1 bg-primary' style={{ width: `${uploadStatus[fileName]}%` }}></div>
                    </div>
                    {/* <Progress
                                        className="w-72"
                                        value={uploadStatus[fileName]}
                                        max={100}
                                        color={uploadStatus[fileName] === 100 ? 'success' : uploadStatus[fileName] === -1 ? 'error' : 'primary'}
                                        ></Progress> */}
                  </div>
                  {uploadStatus[fileName] === 100 ? (
                    <div className='rounded-full w-8 h-8 flex flex-col justify-center items-center border bg-success'>
                      <Icon type='check-icon' className={'w-5 h-5 text-white'} />
                    </div>
                  ) : uploadStatus[fileName] === -1 ? (
                    <div className='rounded-full w-8 h-8 flex flex-col justify-center items-center border bg-error'>
                      <Icon type='xicon' className={'w-5 h-5 text-white'} />
                    </div>
                  ) : (
                    <div className='rounded-full w-8 h-8 flex flex-col justify-center items-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-5 h-5 animate-spin'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                        />
                      </svg>
                    </div>
                  )}
                </div>
              ))}
              <Spacer />
            </div>
          </div>
        ) : null}
        <Footer/>
        </div>
      </main>
    </>
  )
}