import { combineEpics } from 'redux-observable'
import { AcceptInvitationEpic } from './AcceptInvitation/AcceptInvitationEpic'
import { loginEpic } from './Login/LoginEpic'
import { logoutEpic } from './Logout/LogoutEpic'
import { refreshAccessTokenEpic } from './RefreshAccessToken/RefreshAccessTokenEpic'
import { ChangePasswordEpic } from './ChangePassword/ChangePasswordEpic'
import { ChangePasswordConfirmEpic } from './ChangePasswordConfirm/ChangePasswordConfirmEpic'

export const authenticationEpics = combineEpics(
  loginEpic,
  logoutEpic,
  refreshAccessTokenEpic,
  AcceptInvitationEpic,
  ChangePasswordEpic,
  ChangePasswordConfirmEpic
)
