import { useSelector } from 'Flux'
import { State } from 'Flux/Slice'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'

export const useGetLatestDatasetSelector = () => {
  return useSelector(getLatestDataset)
}

export const getLatestDataset = (state: typeof State) => {

  const dataSet = Object.values(state[orderSlice.name].dataset)
  return dataSet.sort((a, b) => a.localIndex - b.localIndex)[dataSet.length - 1]
}
