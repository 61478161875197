import { useEffect, useRef, useState } from 'react'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { useInteraction } from 'Framework/View/Hooks/useInteraction'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'
import Actions, { useDispatch } from 'Flux'
import { Button, FormControl, Label, Modal, TextArea, Toast } from 'View/Common'


export interface IContactModalProps {
  visibileModal$: Observable<void>
  onTrigger$?: Subject<void>;
}


export const ContactModal = ({ visibileModal$, onTrigger$ }: IContactModalProps) => {

  const [visibleModal, setValue] = useState(false)
  const dispatch = useDispatch()

  const onSubmit$ = useInteraction<void>()
  const onCancel$ = useInteraction<void>()

  const question$ = useRef(new BehaviorSubject('')).current
  const message$ = useRef(new Subject<{ type: 'success' | 'error' | 'warning' | 'info', message: string }>()).current


  useEffect(() => {
    const visibileModal$$ = visibileModal$.subscribe(() => {
      setValue(true)
    })
    return () => visibileModal$$.unsubscribe()
  }, [visibileModal$])

  useEffect(() => {
    const onSubmit$$ = onSubmit$.subscribe(() => {
      !question$.value && message$.next({
        type: 'error',
        message: 'Question field is mandatory',
      })
      question$.value && dispatch(
        Actions[orderSlice.name].sendContactRequest({
          question: question$.value,
        }),
      )
      question$.value && onTrigger$ && onTrigger$.next()
      question$.value && setValue(false)
      question$.value && question$.next('')

    })
    return () => onSubmit$$.unsubscribe()
  }, [onSubmit$])

  useEffect(() => {
    const onCancel$$ = onCancel$.subscribe(() => {
      setValue(false)
      question$.next('')
    })
    return () => onCancel$$.unsubscribe()
  }, [onCancel$])

  return (
    <Modal isOpen={visibleModal}>
      <Modal.Body>
        <FormControl>
          <Label>Question: *</Label>
          <TextArea onChange$={question$} />
        </FormControl>
      </Modal.Body>
      <Modal.Actions>
        <Button onClick$={onCancel$} variant='ghost'>Cancel</Button>
        <Button onClick$={onSubmit$} variant='primary'>Submit</Button>
      </Modal.Actions>
      <Toast messageInterval={5000} message$={message$}></Toast>
    </Modal>
  )

}