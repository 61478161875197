import { filter, mergeMap, of } from 'rxjs'
import { catchError, retryWhen, withLatestFrom } from 'rxjs/operators'
import { refreshAccessTokenAndRetry } from '../../../../Framework/Epic/RefreshAccessToken'
import { serverIsNotAvailable } from '../../../../Framework/Epic/ServerNotAvailable'
import { Epic } from '../../../index'
import { Actions } from '../../../Slice'

export const GetAllDatasetsEpic: Epic = (action$, state$, { order, OrderRepository }) => action$.pipe(
  filter(Actions.order.getAllDatasetRequest.match),
  withLatestFrom(state$),
  mergeMap(([action, state]) =>
      OrderRepository.getAllDatasets(action.payload, state.authentication.user).pipe(
      mergeMap((response) => of(order.getAllDatasetSuccess(response))),
      retryWhen(serverIsNotAvailable()),
      catchError(
        refreshAccessTokenAndRetry({
          action$,
          store: state,
          requestAction: action,
          failureActionCreator: order.getAllDatasetFailure,
        }),
      ),
    ),
  ),
)