import { orderSlice } from "Flux/Slice/Order/OrderSlice";
import { useProperty } from "Framework/View";
import { Box, FormControl, Input, Pagination, Table, Select, Button } from "View/Common";
import { PageLayout } from "View/Layout/PageLayout/PageLayout";
import { useEffect, useState } from "react";
import Actions, { useDispatch } from 'Flux'
import { debounceTime, sample, of } from "rxjs";
import { useGetAllDatasets, useGetAllOrders, useGetAuthenticatedUser } from "Flux/Selector";
import { Navigate } from "react-router-dom";
import { IDataSet, IOrder } from "Model";
import { useInteraction } from "Framework/View/Hooks/useInteraction";
import { DetailModal } from "./Modals/DetailModal";
import { resetOrder } from "Repository/Order/OrderRepository";
import { tap, catchError } from 'rxjs/operators';

type TRow = {
  'id': string,
  'name': string,
  'targetTaxa': string,
  'numOfDatasets': number,
  'sumOfSample': number,
  'sumOfNC': number,
};


const takeOptions = [
  {
    value: '10',
    label: '10',
    disabled: false,
  }, {
    value: '100',
    label: '100',
    disabled: false,
  }, {
    value: '250',
    label: '250',
    disabled: false,
  }, {
    value: '500',
    label: '500',
    disabled: false,
  }
]

export const OrderManagementPage: React.FC = () => {

  const dispatch = useDispatch()

  const datasets = useGetAllDatasets()
  const [isShowExpert, setShowExpert] = useState<boolean>(false);

  const handleExpertChange = () => {
    setShowExpert(prev => !prev);
  }

  const [message, setMessage] = useState<string>("");
  const [messageIsError, setMessageIsError] = useState<boolean>(false);

  const showInfo = (msg: string) => {
    setMessage(msg);
    setMessageIsError(false);
  }

  const showError = (msg: string) => {
    setMessage(msg);
    setMessageIsError(true);
  }

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(''), 3000); // Clear after 3 seconds
      return () => clearTimeout(timer); // Cleanup timer if component unmounts or message changes
    }
  }, [message]);

  const search$ = useProperty<string>('')
  const take$ = useProperty<string>("10")
  const [page, setPage] = useState<number>(1)

  const [modifiedOrder, setModifiedOrder] = useState<TRow[]>([])
  const [orderId, setOrderId] = useState<string>("")


  const authenticatedUser = useGetAuthenticatedUser()
  const { orders, total } = useGetAllOrders()

  const onPagination$ = useInteraction<number>();
  const onDetails$ = useInteraction<{id:string, name:string}>();
  const onDownload$ = useInteraction<string>()
  const onDownloadFiles$ = useInteraction<IOrder>()
  const onResetOrder$ = useInteraction<IOrder>();

  useEffect(() => {
    const onPagination$$ = onPagination$.subscribe((page) => {

      setPage(page)
    })

    return () => onPagination$$.unsubscribe()
  }, [onPagination$])

  useEffect(() => {
    const onResetOrder$$ = onResetOrder$.subscribe((data) => {
// TODO: add "i know what I'm doing" safeguard (checkbox)"
      resetOrder({orderId: data.id}, authenticatedUser)
      .pipe(
        tap(response => {
          showInfo("Order reset successfully");
          console.log("Order reset successfully");
        }),
        catchError(error => {
          showError("Order reset failed");
          console.log("Order reset failed");
          return of(error);
        })
      )
      .subscribe();
    });
    return () => onResetOrder$$.unsubscribe()
  }, [onResetOrder$, orders])

  useEffect(() => {
    const onDownloadFiles$$ = onDownloadFiles$.subscribe((data) => {
      orders.forEach((order)=>{ 
        if(order.id === data.id){
          order.dataSets && order.dataSets.forEach((dataset:IDataSet)=>{
            dataset.samples?.forEach(sample=>{
              dispatch(
                Actions[orderSlice.name].getOrderFilesRequest({
                  orderId: dataset.id,
                  fileId :sample.forwardFile.id,
                  fileName:sample.forwardFile.originalFileName
                })
              )
              dispatch(
                Actions[orderSlice.name].getOrderFilesRequest({
                  orderId: dataset.id,
                  fileId :sample.reverseFile.id,
                  fileName:sample.reverseFile.originalFileName
                })
              )
            })
            dataset.negativeControls?.forEach(negativeControl=>{
              dispatch(
                Actions[orderSlice.name].getOrderFilesRequest({
                  orderId: dataset.id,
                  fileId :negativeControl.forwardFile.id,
                  fileName:negativeControl.forwardFile.originalFileName
                })
              )
              dispatch(
                Actions[orderSlice.name].getOrderFilesRequest({
                  orderId: dataset.id,
                  fileId :negativeControl.reverseFile.id,
                  fileName:negativeControl.reverseFile.originalFileName
                })
              )
            })
          })

        }
      })
    })

    return () => onDownloadFiles$$.unsubscribe()
  }, [onDownloadFiles$,orders])





  useEffect(() => {
    const search$$ = search$.pipe(debounceTime(400)).subscribe(() => {
      dispatch(
        Actions[orderSlice.name].getAllOrderRequest({
          take: parseInt(take$.value),
          page: page,
          filters: [
            {
              value: search$.value,
              parameter: 'name',
              filterType: 'substr',
            },
          ],
        })
      )
    })
    const take$$ = take$.subscribe(() => {
      dispatch(
        Actions[orderSlice.name].getAllOrderRequest({
          take: parseInt(take$.value),
          page: page,
          filters: [
            {
              value: search$.value,
              parameter: 'name',
              filterType: 'substr',
            },
          ],
        })
      )
    })

    return () => {
      search$$.unsubscribe()
      take$$.unsubscribe()
    }
  }, [dispatch, take$, page, search$])

  useEffect(() => {
    if (orders) {
      const convert = (order: IOrder): TRow => {
        return {
          "id": order.id,
          "name": order.name,
          "targetTaxa": order.targetTaxa,
          "numOfDatasets": calculateLength(order.dataSets!)[0],
          "sumOfSample": calculateLength(order.dataSets!)[1],
          "sumOfNC": calculateLength(order.dataSets!)[2],
        }
      }

      const calculateLength = (dataset: IDataSet[]) => {
        let sum: number = 0
        let sumOfSample = 0
        let sumOfNC = 0

        dataset?.map((dataset: IDataSet) => {
          if (dataset.samples!.length > 0) {
            sum = sum + 1
            sumOfSample = sumOfSample + dataset.samples!.length
            sumOfNC = sumOfNC + dataset.negativeControls!.length
          }
        })
        return [sum, sumOfSample, sumOfNC]
      }

      const format = orders.map((order) => {
        return convert(order)
      })

      setModifiedOrder(format)

    }
  }, [orders])

  useEffect(() => {
    const onDownload$$ = onDownload$.subscribe((val) => {
      dispatch(
        Actions[orderSlice.name].getDatasetReportRequest({
          orderId: val
        })
      )
    })
    return () => onDownload$$.unsubscribe()
  }, [onDownload$])

  if (!authenticatedUser) {
    return <Navigate to={'/'} />
  }

  return (
    <PageLayout>
      <Box
      >

      {message && (
              <div
                className={`absolute top-10 left-1/2 transform -translate-x-1/2 p-4 rounded shadow-md transition-opacity duration-300 ${
                  messageIsError
                    ? 'bg-red-500 text-white'
                    : 'bg-green-500 text-white'
                }`}
              >
                {message}
              </div>
            )}

        <div className="flex">
          <div className="w-full">
            <FormControl>
              <Input type='text' onChange$={search$}
                placeholder={"Search by Keyword "} />
            </FormControl>
          </div>
          <div>
            <FormControl>
              <Select
                onChange$={take$}
                options={takeOptions}
                initialValue={take$.value}
              />
            </FormControl>
          </div>
        </div>
        <Table type="table-auto">
          <Table.Head>
            <Table.Row>
              <Table.Cell key={'order_name'}><div className="text-center text-sm font-bold">Name</div></Table.Cell>
              <Table.Cell key={'order_taxon'}><div className="text-center text-sm font-bold">Amplicon</div></Table.Cell>
              <Table.Cell key={'total_dataset'}><div className="text-center text-sm font-bold">No. of Datasets</div></Table.Cell>
              <Table.Cell key={'total_samples'}><div className="text-center text-sm font-bold">No. of Samples</div></Table.Cell>
              <Table.Cell key={'total_negative_controls'}><div className="text-center text-sm font-bold">No. of NC</div></Table.Cell>
              <Table.Cell key={'order_report'}><div className="text-center text-sm font-bold">Report</div></Table.Cell>
              <Table.Cell key={'order_files'}><div className="text-center text-sm font-bold">Download Files</div></Table.Cell>
              {isShowExpert?
              <Table.Cell key={'reset_order'}><div className="text-center text-sm font-bold">Actions</div></Table.Cell> : <div></div>
              }
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {modifiedOrder?.map((item, index) => (
              item.numOfDatasets > 0 &&
              <Table.Row key={index + 1}>
                <Table.Cell key={`${index + 1}_order_name`}>
                  <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center"  onClick={() => onDetails$.next({id:item.id,name:item.name})}>
                    {item.name}
                  </div>
                </Table.Cell>
                <Table.Cell key={`${index + 1}_order_taxon`}>
                  <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center" onClick={() => onDetails$.next({id:item.id,name:item.name})}>
                    {item.targetTaxa}
                  </div>
                </Table.Cell>
                <Table.Cell key={`${index + 1}_total_datase`}>
                  <div className="flex items-center justify-center space-x-3" onClick={() => onDetails$.next({id:item.id,name:item.name})}>
                    <div className="px-3 py-1 bg-primary flex items-center justify-center text-sm">
                      {item.numOfDatasets}
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell key={`${index + 1}_total_samples`}>
                  <div className="flex items-center justify-center space-x-3" onClick={() => onDetails$.next({id:item.id,name:item.name})}>
                    <div className="px-3 py-1 bg-primary flex items-center justify-center text-sm">
                      {item.sumOfSample}
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell key={`${index + 1}_total_negative_controls`}>
                  <div className="flex items-center justify-center space-x-3" onClick={() => onDetails$.next({id:item.id,name:item.name})}>
                    <div className="px-3 py-1 bg-primary flex items-center justify-center text-sm">
                      {item.sumOfNC}
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell key={`${index + 1}_order_report`}>
                  <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center hover:underline" onClick={() => onDownload$.next(item.id)}>
                    Download
                  </div>
                </Table.Cell>
                <Table.Cell key={`${index + 1}_order_files`}>
                  <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center hover:underline" onClick={() => onDownloadFiles$.next(item)}>
                    Files
                  </div>
                </Table.Cell>
                {isShowExpert ?
                      <Table.Cell key={`${index + 1}_reset_order`}>
                      <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center hover:underline" onClick={() => onResetOrder$.next(item)}>
                          Force Resubmit
                        </div>
                      </Table.Cell> : <div></div>}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pagination onClick$={onPagination$} page={page} total={total} take={parseInt(take$.value)}></Pagination>

        <div className="flex justify-center text-sm text-gray-400 bg-white ">
          <label>
            <input type="checkbox" checked={isShowExpert} onChange={handleExpertChange} /> Expert Mode
          </label>
        </div>
      </Box>
      <DetailModal visibileModal$={onDetails$}></DetailModal>
    </PageLayout>
  );
}