import { Box, Button, Checkbox, FormControl, Icon, Label, Paragraph, Stepper, Toast, Tooltip } from 'View/Common'
import { Spacer } from 'View/Common/Layout/Spacer'
import { STEPS } from '../HomePage'
import { Translation } from 'Translation/Translation'
import { useInteraction } from 'Framework/View/Hooks/useInteraction'
import { BehaviorSubject, Subject } from 'rxjs'
import { useEffect, useRef, useState } from 'react'
import { useGetCurrentStep, useGetDatasetSelector, useGetOrderSelector } from 'Flux/Selector'
import { CancelModal } from '../Modals/CancelModal'
import Actions, { useDispatch } from 'Flux'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'
import { Heading2 } from 'View/Common/Typography/Heading2'
import { ContactModal } from '../Modals/ContactModal'

export const OrderReportPage = () => {
  const { translate } = Translation
  const dispatch = useDispatch()

  const currentStep = useGetCurrentStep()
  const datasets = useGetDatasetSelector()
  const order = useGetOrderSelector()

  const message$ = useRef(new Subject<{ type: 'success' | 'error' | 'warning' | 'info', message: string }>()).current
  const agreement$ = useRef(new BehaviorSubject<boolean>(false)).current
  const accept$ = useRef(new BehaviorSubject<boolean>(false)).current

  const [agreementError, setAgreementError] = useState<boolean>(true)
  const [acceptError, setAcceptError] = useState<boolean>(true)
  const [price, setPrice] = useState<string>()

  const onNext$ = useInteraction<void>()
  const onCancel$ = useInteraction<void>()
  const onContact$ = useInteraction<void>()
  const onContactSent$ = useInteraction<void>()

  useEffect(() => {
    const sort = datasets.reduce((rows, dataset) => {
      return [
        ...rows,
        ...(dataset.samples || []),
      ]
    }, [] as (any)[])
    setPrice(JSON.stringify(sort.length * 0) + '€')

  }, [])

  useEffect(() => {
    const subscription = onNext$.subscribe(() => {
      if (agreement$.value && accept$.value) {
        dispatch(Actions[orderSlice.name].submitOrderRequest({ orderId: order?.id || '' }))
        dispatch(Actions[orderSlice.name].stepNext())
      } else {
        !agreement$.value && setAgreementError(false)
        agreement$.value && setAgreementError(true)
        !accept$.value && setAcceptError(false)
        accept$.value && setAcceptError(true)

        message$.next({
          type: 'error',
          message: 'You need to to accept the Data Processing Agreement as well as the Terms & Conditions of spcfy GmbH in order to continue.',
        })
      }

    })

    return () => subscription.unsubscribe()
  }, [order, message$, onNext$])

  useEffect(() => {

    const subscription = onContactSent$.subscribe(() => {
      message$.next({
        type: 'success',
        message: 'Your message has been successfully sent',
      })
    })
    return () => subscription.unsubscribe()

  }, [onContactSent$])
  return (
    <div>
      <Box
        action={
          <>
            <Button variant='primary' onClick$={onNext$}>{translate('home:next')}</Button>
          </>
        }
      >
        <Stepper steps={STEPS} currentStep={currentStep} />
        <Spacer />
        <div className='flex justify-end'>
          <Button variant='contact' onClick$={onContact$}>Contact</Button>
        </div>
        <div className='flex justify-center items-center'>
          <Heading2>Order name: {order.name}</Heading2>
        </div>
        <div>
          <div className='p-3 text-red-600 font-'>
            <Paragraph>We have gathered all information to run the analysis against BOLD, NCBI and the RDP classifier databases to
              generate your OTU
              report.</Paragraph>
          </div>
          <div className='p-3'>
            <Paragraph>Launching the OTU creation will start the bioinformatic pipeline, incurring costs, which we will charge to your
              account.</Paragraph>
          </div>
          <div className='p-3'>
            <Paragraph>Depending on the size of dataset, postprocessing can take up to 24 hours.</Paragraph>
            <Paragraph>As soon as the OTU table is created, we will send you an email notification including a link to download the
              results.</Paragraph>
          </div>
          <div className='px-2 py-10'>
            <FormControl>
              <Label>Total price for order: *</Label>
              <div className='w-1/2 flex items-center py-4'>
                <div className='border-2 w-full h-12 rounded-s flex justify-center items-center'>
                  {price}
                </div>
                <Tooltip
                  tooltip='Prices are displayed in net€ without VAT. By accepting the Terms & Conditions, you confirm that you will receive an invoice for the amount shown at the email address of your customer account'>
                  <Icon type='information-circle-icon' className='h-8 w-8'></Icon>
                </Tooltip>
              </div>
            </FormControl>
          </div>
          <div className='p-2 text-base font-bold'>
            An invoice with the costs of the analysis will be sent to the email of this account
          </div>
          <div className='p-2'>
            <FormControl>
              <Checkbox valid={agreementError} onChange$={agreement$}
                label={'I accept the Data Processing Agreement of spcfy Gmbh.'}></Checkbox>
            </FormControl>
            <FormControl>
              <Checkbox valid={acceptError} onChange$={accept$} label={'I accept the Terms & Conditions of spcfy Gmbh.'}></Checkbox>
            </FormControl>
          </div>
        </div>
      </Box>
      <Toast messageInterval={5000} message$={message$}></Toast>
      <CancelModal visibileModal$={onCancel$}></CancelModal>
      <ContactModal visibileModal$={onContact$} onTrigger$={onContactSent$}></ContactModal>
    </div>
  )
}