import { Translation } from '../../../../Translation/Translation'
import { useEffect, useRef } from 'react'
import { useProperty } from '../../../../Framework/View'
import { useInteraction } from '../../../../Framework/View/Hooks/useInteraction'
import { Box, Button, FormControl, Input, Label, Paragraph, Toast } from '../../../Common'
import { Spacer } from '../../../Common/Layout/Spacer'
import { PageLayout } from '../../../Layout/PageLayout/PageLayout'
import Actions, { useDispatch } from 'Flux'
import { authenticationSlice } from 'Flux/Slice/Authentication/AuthenticationSlice'
import { Subject } from 'rxjs'

export const ChangePasswordPage = () => {
  const { translate } = Translation
  const dispatch = useDispatch()

  const message$ = useRef(new Subject<{ type: 'success' | 'error' | 'warning' | 'info', message: string }>()).current
  const email$ = useProperty<string>('')
  const onSubmit$ = useInteraction<void>()

  useEffect(() => {
    const onSubmit$$ = onSubmit$.subscribe(() => {
      dispatch(
        Actions[authenticationSlice.name].changePasswordRequest({
          email: email$.value,
        }),
      )
      message$.next({
        type: 'success',
        message: "Password reset link has been sent to the email",
      })
      email$.next('')
    })
    return () => onSubmit$$.unsubscribe()
  }, [onSubmit$, email$, dispatch])

  return (
    <PageLayout>
      <Box
        title={translate('changePassword:title')}
        action={<Button variant='primary' onClick$={onSubmit$}>{translate('changePassword:confirm')}</Button>}
      >
        <Spacer>
          <Paragraph>{translate('changePassword:welcomeText')}</Paragraph>
        </Spacer>
        <FormControl>
          <Label>Email</Label>
          <Input type='text' onChange$={email$} placeholder={translate('user:email')} />
        </FormControl>
      </Box>
      <Toast messageInterval={5000} message$={message$}></Toast>
    </PageLayout>
  )
}