import { useEffect, useState } from 'react'
import { Observable } from 'rxjs'
import { useInteraction } from 'Framework/View/Hooks/useInteraction'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'
import Actions, { useDispatch } from 'Flux'
import { Button, Modal, Table } from 'View/Common'
import { useGetAllDatasets } from 'Flux/Selector'
import { Heading2 } from 'View/Common/Typography/Heading2'
import { IDataSet } from 'Model'
import { Spacer } from 'View/Common/Layout/Spacer'


export interface IContactModalProps {
  visibileModal$: Observable<{id:string, name:string}>
}


export const DetailModal = ({ visibileModal$ }: IContactModalProps) => {

  const datasets = useGetAllDatasets()
  const [visibleModal, setValue] = useState(false)
  const dispatch = useDispatch()

  const onCancel$ = useInteraction<void>()
  const onDatasetDetails$ = useInteraction<IDataSet>();

  const [datasetDetails, setDatasetDetails] = useState<IDataSet>();
  const [orderName, setOrderName] = useState<string>("");

  useEffect(() => {
    const visibileModal$$ = visibileModal$.subscribe((val) => {
      setOrderName(val.name)
      dispatch(
        Actions[orderSlice.name].getAllDatasetRequest({
          orderId: val.id
        })
      )

      setValue(true)
    })
    return () => visibileModal$$.unsubscribe()
  }, [visibileModal$])


  useEffect(() => {
    const onCancel$$ = onCancel$.subscribe(() => {
      setValue(false)
      setDatasetDetails(undefined)
    })
    return () => onCancel$$.unsubscribe()
  }, [onCancel$])

  useEffect(() => {
    const onDatasetDetails$$ = onDatasetDetails$.subscribe((dataset) => {
      setDatasetDetails(dataset)
    })
    return () => onDatasetDetails$$.unsubscribe()
  }, [onDatasetDetails$])

  return (
    <Modal isOpen={visibleModal}>
      <Modal.Body>
        <div className='flex flex-col justify-center items-center'>
          <Heading2>Order Name: {orderName}</Heading2>
          <Heading2>Datasets</Heading2>
        </div>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell key={'dataset_Id'}><div className="text-center text-sm font-bold">ID</div></Table.Cell>
              <Table.Cell key={'dataset_samples'}><div className="text-center text-sm font-bold">No.of Samples</div></Table.Cell>
              <Table.Cell key={'dataset_NC'}><div className="text-center text-sm font-bold">No.of NC</div></Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {datasets?.map((item, index) => (
              item.samples && item.samples?.length > 0 && (
                <>
                  <Table.Row key={index + 1} onMouseEnter={() => onDatasetDetails$.next(item)}>
                    <Table.Cell key={`${index + 1}_dataset_Id`}>
                      <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center">
                        {item.id}
                      </div>
                    </Table.Cell>
                    <Table.Cell key={`${index + 1}_dataset_NC`}>
                      <div className="flex items-center justify-center space-x-3">
                        <div className="px-3 py-1 bg-primary flex items-center justify-center text-sm">
                          {item.samples.length}
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell key={`${index + 1}_dataset_report`}>
                      <div className="flex items-center justify-center space-x-3">
                        <div className="px-3 py-1 bg-primary flex items-center justify-center text-sm">
                          {item.negativeControls?.length}
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </>
              )

            ))}

          </Table.Body>
        </Table>
        
        {
          datasetDetails && (
            <>
              <Spacer />
              <div className='flex justify-center items-center'>
                <Heading2>Datasets({datasetDetails.id}) Files</Heading2>
              </div>
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Cell key={'customer_Id'}><div className="text-center text-sm font-bold">Customer Sample ID</div></Table.Cell>
                    <Table.Cell key={'type'}><div className="text-center text-sm font-bold">Type</div></Table.Cell>
                    <Table.Cell key={'forward_file'}><div className="text-center text-sm font-bold">Forward File</div></Table.Cell>
                    <Table.Cell key={'reverse_file'}><div className="text-center text-sm font-bold">Reverse File</div></Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {datasetDetails.samples && datasetDetails.samples.map((sample, ind) => (
                    <Table.Row key={ind + 1}>
                      <Table.Cell key={`${ind + 1}_customer_Id`}>
                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center">
                          {sample.customerSampleId}
                        </div>
                      </Table.Cell>
                      <Table.Cell key={`${ind + 1}_type`}>
                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center">
                          Sample
                        </div>
                      </Table.Cell>
                      <Table.Cell key={`${ind + 1}_forward_file`}>
                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center">
                          {sample.forwardFile.originalFileName}
                        </div>
                      </Table.Cell>
                      <Table.Cell key={`${ind + 1}_reverse_file`}>
                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center">
                          {sample.reverseFile.originalFileName}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))
                  }

                  {datasetDetails.negativeControls && datasetDetails.negativeControls.map((negativeControl, ind) => (
                    <Table.Row key={ind + 1}>
                      <Table.Cell key={`${ind + 1}_customer_Id`}>
                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center">
                          {negativeControl.customerSampleId}
                        </div>
                      </Table.Cell>
                      <Table.Cell key={`${ind + 1}_type`}>
                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center">
                          Negative control
                        </div>
                      </Table.Cell>
                      <Table.Cell key={`${ind + 1}_forward_file`}>
                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center">
                          {negativeControl.forwardFile.originalFileName}
                        </div>
                      </Table.Cell>
                      <Table.Cell key={`${ind + 1}_reverse_file`}>
                        <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis text-center">
                          {negativeControl.reverseFile.originalFileName}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))
                  }


                </Table.Body>
              </Table>
            </>
          )
        }
      </Modal.Body>
      <Modal.Actions>
        <Button onClick$={onCancel$} variant='ghost'>Cancel</Button>
      </Modal.Actions>
    </Modal>
  )

}