import { Environment } from 'environment'
import { fileUploadWithProgress, post, put,get,download} from 'Repository'
import { Observable } from 'rxjs'
import { IDataSet, IOrder, IUser } from 'Model'
import { IBulkResponse } from 'Framework/Repository/Response/IBulkResponse'

export const createOrder = (data: { name: string; targetTaxa: string, allowSingletons: boolean }, user?: IUser): Observable<any> => {
  return post(`${Environment.API_URL}/v1/order`, data, user)
}

export const createDataset = (data: { orderId: string }, user?: IUser): Observable<any> => {
  return post(`${Environment.API_URL}/v1/upload/data-set`, data, user)
}

export const getAllDatasets = (data: { orderId: string }, user?: IUser): Observable<{
  resources: IDataSet[]
}> => {
  return get(`${Environment.API_URL}/v1/upload/data-set/${data.orderId}/alldatasets`, data, user)
}

export const getAllOrders = (
  data: { take: number; page: number; filters: { parameter: string; value: any; filterType: string }[] },
  user?: IUser
): Observable<IBulkResponse<IOrder>> => {
  return get(`${Environment.API_URL}/v1/order/find`, data, user)
}


export const uploadSample = (
  data: {
    datasetId: string
    forwardFile: File,
    reverseFile: File
  },
  user?: IUser,
): Observable<any> => {
  return fileUploadWithProgress(`${Environment.API_URL}/v1/upload/data-set/${data.datasetId}/sample`, data, user)
}

export const uploadNegativeControls = (
  data: {
    datasetId: string
    forwardFile: File,
    reverseFile: File
  },
  user?: IUser,
): Observable<any> => {
  return fileUploadWithProgress(`${Environment.API_URL}/v1/upload/data-set/${data.datasetId}/negative-control`, data, user)
}

export const updateNegativeControl = (
  data: {
    datasetId: string
    negativeControlId: string
    customerSampleId: string,
    forwardPrimer: string,
    reversePrimer: string,
    minBasePairLength:number
  },
  user?: IUser,
): Observable<{ resource: IDataSet }> => {
  return put(`${Environment.API_URL}/v1/upload/data-set/${data.datasetId}/negative-control/${data.negativeControlId}`, data, user)
}

export const updateSample = (
  data: {
    datasetId: string
    sampleId: string
    customerSampleId: string,
    forwardPrimer: string,
    reversePrimer: string,
    minBasePairLength:number
  },
  user?: IUser,
): Observable<{ resource: IDataSet }> => {
  return put(`${Environment.API_URL}/v1/upload/data-set/${data.datasetId}/sample/${data.sampleId}`, data, user)
}

export const submitOrder = (
  data: {
    orderId: string
  },
  user?: IUser,
): Observable<{ resource: IOrder }> => {
  return put(`${Environment.API_URL}/v1/order/submit`, data, user)
}

export const resetOrder = (
  data: {
    orderId: string
  },
  user?: IUser,
): Observable<{ resource: IOrder }> => {
  return put(`${Environment.API_URL}/v1/order/reset`, data, user)
}

export const getDatasetReport = (
  data: {
    orderId: string
  },
  user?: IUser,
): Observable<Blob> => {
  return download(`${Environment.API_URL}/v1/upload/data-set/${data.orderId}/download`, data, user)
}

export const getOrderFiles = (
  data: {
    orderId: string,
    fileId: string,
    fileName: string
  },
  user?: IUser,
): Observable<Blob> => {
  return download(`${Environment.API_URL}/v1/upload/data-set/${data.orderId}/download/${data.fileId}/${data.fileName}`, data, user)
}