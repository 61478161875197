import { filter, mergeMap, of } from 'rxjs'
import { catchError, retryWhen, withLatestFrom } from 'rxjs/operators'
import { refreshAccessTokenAndRetry } from '../../../../Framework/Epic/RefreshAccessToken'
import { serverIsNotAvailable } from '../../../../Framework/Epic/ServerNotAvailable'
import { Epic } from '../../../index'
import { orderSlice } from '../../../Slice/Order/OrderSlice'

export const SubmitOrderEpic: Epic = (action$, state$, { OrderRepository, [orderSlice.name]: order }) =>
  action$.pipe(
    filter(order.submitOrderRequest.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      OrderRepository.submitOrder(action.payload, state.authentication.user).pipe(
        mergeMap((response) => of(order.submitOrderSuccess(response))),
        retryWhen(serverIsNotAvailable()),
        catchError(
          refreshAccessTokenAndRetry({
            action$,
            store: state,
            requestAction: action,
            failureActionCreator: order.sendContactFailure,
          }),
        ),
      ),
    ),
  )