import { Environment } from 'environment'
import { post } from 'Repository'
import { Observable } from 'rxjs'
import { IUser } from 'Model'

export const refreshAccessToken = (data: { refresh: string }, user?: IUser): Observable<IUser> => {
  return post(`${Environment.API_URL}/v1/auth/refresh`, data, user)
}

export const authenticate = (data: { email: string; password: string }): Observable<IUser> => {
  return post(`${Environment.API_URL}/v1/auth/login`, data)
}

export const changePasswordConfirm = (data: { token?: string; password: string }, user?: IUser): Observable<void> => {
  return post(`${Environment.API_URL}/v1/auth/change-password-confirm`, data, user)
}

export const acceptInvitation = (data: { invitationId: string, password: string }) => {
  return post<typeof data, {}>(`${Environment.API_URL}/v1/user/laboratory/invitation/accept`, data)
}

export const changePassword = (data: { email: string }, user?: IUser): Observable<void> => {
  return post(`${Environment.API_URL}/v1/auth/change-password`, data, user)
}