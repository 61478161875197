import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { removeRequest } from 'Flux'
import { IBulkResponse } from 'Framework/Repository/Response/IBulkResponse'
import { IDataSet, IOrder, IPrimer, IContact } from 'Model'


export enum EPrimerSearchStatus {
  IN_PROGRESS,
  NOT_FOUND,
  SUCCESS
}

export interface IOrderState {
  openRequests: string[]
  order: Partial<IOrder>,
  currentStep: number,
  dataset: Record<string, IDataSet>,
  uploadStatus: Record<string, number>
  primer?: IPrimer;
  primerNotFound: EPrimerSearchStatus,
  datasets:IDataSet[],
  orders:IOrder[],
  total:number
}

const initialState: IOrderState = {
  openRequests: [],
  order: {},
  currentStep: 0,
  dataset: {},
  uploadStatus: {},
  primerNotFound: EPrimerSearchStatus.IN_PROGRESS,
  datasets:[],
  orders:[],
  total:0
}

const reducers = {
  setUploadStatus: (state: Draft<IOrderState>, action: PayloadAction<{ fileName: string; percent: number }>) => {
    if (action.payload.percent === 100) {
      delete state.uploadStatus[action.payload.fileName]
    } else {
      state.uploadStatus[action.payload.fileName] = action.payload.percent
    }
  },
  createOrderRequest: (state: Draft<IOrderState>, action: PayloadAction<{ name: string; targetTaxa: string; allowSingletons: boolean }>) => {
    state.openRequests.push(action.type)
  },
  createOrderSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resource: IOrder }>) => {
    state.openRequests = removeRequest(state, action)
    state.currentStep = 1
    state.order = action.payload.resource
  },
  createOrderFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  createDatasetRequest: (state: Draft<IOrderState>, action: PayloadAction<{ orderId: string | undefined; }>) => {
    state.openRequests.push(action.type)
  },
  createDatasetSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resource: IDataSet }>) => {
    state.openRequests = removeRequest(state, action)
    state.dataset[action.payload.resource.id] = action.payload.resource
    state.dataset[action.payload.resource.id].localIndex = Object.keys(state.dataset).length
  },
  createDatasetFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  getPrimerRequest: (state: Draft<IOrderState>, action: PayloadAction<{
    forwardSequence: string,
    reverseSequence: string
  }>) => {
    //state.openRequests.push(action.type);
  },
  getPrimerSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resource: IPrimer }>) => {
    state.primer = action.payload.resource
  },
  getPrimerFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: any }>) => {
    if (action.payload.error.status === 404) {
      state.primerNotFound = EPrimerSearchStatus.NOT_FOUND
    }
    delete state.primer
  },
  uploadSampleRequest: (state: Draft<IOrderState>, action: PayloadAction<{
    datasetId: string;
    forwardFile: any,
    reverseFile: any
  }>) => {
    state.openRequests.push(action.type)
  },
  uploadSampleSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resource: IDataSet }>) => {
    state.openRequests = removeRequest(state, action)
    const { resource } = action.payload
    state.dataset[resource.id] = { ...state.dataset[resource.id], ...resource }
  },
  uploadSampleFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  uploadNegativeControlRequest: (state: Draft<IOrderState>, action: PayloadAction<{
    datasetId: string;
    forwardFile: any,
    reverseFile: any
  }>) => {
    state.openRequests.push(action.type)
  },
  uploadNegativeControlSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resource: IDataSet }>) => {
    state.openRequests = removeRequest(state, action)
    const { resource } = action.payload
    state.dataset[resource.id] = { ...state.dataset[resource.id], ...resource }
  },
  uploadNegativeControlFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  
  createPrimerAddRequest: (state: Draft<IOrderState>, action: PayloadAction<IPrimer>) => {
    state.openRequests.push(action.type)
  },
  createPrimerAddSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resource: IPrimer }>) => {
    state.openRequests = removeRequest(state, action)
    state.primerNotFound = EPrimerSearchStatus.SUCCESS
  },
  createPrimerAddFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  updateSampleRequest: (state: Draft<IOrderState>, action: PayloadAction<{
    datasetId: string; sampleId: string; forwardPrimer: string; reversePrimer: string, customerSampleId: string,  minBasePairLength:number
  }>) => {
    state.openRequests.push(action.type)
  },
  updateSampleSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resource: IDataSet }>) => {
    const { resource } = action.payload
    state.dataset[resource.id] = { ...state.dataset[resource.id], ...resource }
    state.openRequests = removeRequest(state, action)
  },
  updateSampleFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  updateNegativeControlRequest: (state: Draft<IOrderState>, action: PayloadAction<{
    datasetId: string; negativeControlId: string; forwardPrimer: string; reversePrimer: string, customerSampleId: string,  minBasePairLength:number
  }>) => {
    state.openRequests.push(action.type)
  },
  updateNegativeControlSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resource: IDataSet }>) => {
    const { resource } = action.payload
    state.dataset[resource.id] = { ...state.dataset[resource.id], ...resource }
    state.openRequests = removeRequest(state, action)
  },
  updateNegativeControlFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  submitOrderRequest: (state: Draft<IOrderState>, action: PayloadAction<{ orderId: string }>) => {
    state.openRequests.push(action.type)
  },
  submitOrderSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resource: IOrder }>) => {
    state.openRequests = removeRequest(state, action)
    state.order = action.payload.resource
  },
  submitOrderFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  sendContactRequest: (state: Draft<IOrderState>, action: PayloadAction<IContact>) => {
    state.openRequests.push(action.type)
  },
  sendContactSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resource: IContact }>) => {
    state.openRequests = removeRequest(state, action)
  },
  sendContactFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  getAllDatasetRequest: (state: Draft<IOrderState>, action: PayloadAction<{ orderId: string }>) => {
    state.openRequests.push(action.type)
  },
  getAllDatasetSuccess: (state: Draft<IOrderState>, action: PayloadAction<{ resources: IDataSet[] }>) => {
    state.datasets = action.payload.resources
    state.openRequests = removeRequest(state, action)
  },
  getAllDatasetFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  getAllOrderRequest: (state: Draft<IOrderState>, action: PayloadAction<{ take: number; page: number; filters: { parameter: string; value: any; filterType: string }[] }>) => {
    state.openRequests.push(action.type)
  },
  getAllOrderSuccess: (state: Draft<IOrderState>, action: PayloadAction<IBulkResponse<IOrder>>) => {
    state.orders = action.payload.resources
    state.total = action.payload.count
    state.openRequests = removeRequest(state, action)
  },
  getAllOrderFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  getDatasetReportRequest: (state: Draft<IOrderState>, action: PayloadAction<{ orderId: string }>) => {
    state.openRequests.push(action.type)
  },
  getDatasetReportSuccess: (state: Draft<IOrderState>, action: PayloadAction<any>) => {
    state.openRequests = removeRequest(state, action)
  },
  getDatasetReportFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  getOrderFilesRequest: (state: Draft<IOrderState>, action: PayloadAction<{ orderId: string, fileId: string, fileName: string }>) => {
    state.openRequests.push(action.type)
  },
  getOrderFilesSuccess: (state: Draft<IOrderState>, action: PayloadAction<any>) => {
    state.openRequests = removeRequest(state, action)
  },
  getOrderFilesFailure: (state: Draft<IOrderState>, action: PayloadAction<{ error: Error }>) => {
    state.openRequests = removeRequest(state, action)
  },
  stepNext: (state: Draft<IOrderState>) => {
    state.currentStep = state.currentStep + 1
  },
  stepBack: (state: Draft<IOrderState>) => {
    if (state.currentStep > 0) {
      state.currentStep = state.currentStep - 1
      if (state.currentStep === 1 || state.currentStep === 0) {
        state.dataset = {}
        state.primerNotFound = EPrimerSearchStatus.IN_PROGRESS
      } else if (state.currentStep === 2) {
        delete state.primer
      }
    }
  },
  reset: () => {
    return initialState
  },
}

export const orderSlice = createSlice<IOrderState, typeof reducers, 'order'>({
  name: 'order',
  initialState,
  reducers,
})