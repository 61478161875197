import { filter, mergeMap, of } from 'rxjs';
import { catchError, retryWhen, withLatestFrom } from 'rxjs/operators';
import { refreshAccessTokenAndRetry } from '../../../../Framework/Epic/RefreshAccessToken';
import { serverIsNotAvailable } from '../../../../Framework/Epic/ServerNotAvailable';
import { Epic } from '../../../index';
import { Actions } from '../../../Slice';

export const GetOrderFilesEpic: Epic = (action$, state$, { order, OrderRepository }) => action$.pipe(
  filter(Actions.order.getOrderFilesRequest.match),
  withLatestFrom(state$),
  mergeMap(([action, state]) =>
    OrderRepository.getOrderFiles(action.payload, state.authentication.user).pipe(
      mergeMap((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = action.payload.fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(downloadLink);
        return of(order.getOrderFilesSuccess(response));
      }),
      retryWhen(serverIsNotAvailable()),
      catchError(
        refreshAccessTokenAndRetry({
          action$,
          store: state,
          requestAction: action,
          failureActionCreator: order.getOrderFilesFailure,
        }),
      ),
    ),
  ),
);
