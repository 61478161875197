import { TAvailableLanguage } from './Translation'

export const AVAILABLE_LANGUAGES = ['de_DE', 'en_US'] as const

export const parseContent = (root: Record<string, any>): { de_DE: Record<string, any>; en_US: Record<string, any> } => {
  const translation = { de_DE: {}, en_US: {} }

  const parse = (root: Record<TAvailableLanguage, any>, path: string[]) => {
    Object.keys(root).forEach((key) => {
      if ((AVAILABLE_LANGUAGES as readonly string[]).includes(key)) {
        write(translation, [key, ...path], root[key as TAvailableLanguage])
      } else if (key !== 'PATH') {
        parse(root[key as TAvailableLanguage], [...path, key])
      }
    })
  }
  parse(root, [])

  return translation
}

export const write = (obj: any, keys: string[], v: any) => {
  if (keys.length === 0) {
    return v
  }
  if (keys.length === 1) {
    obj[keys[0]] = v
  } else {
    const [key, ...remainingKeys] = keys
    const nextKey = remainingKeys[0]
    const nextRemainingKeys = remainingKeys.slice(1)

    if (typeof nextKey === 'number') {
      // create array
      if (!obj[key]) {
        obj[key] = []
      }

      // Fill empty index with empty object
      if (obj[key].length < nextKey + 1) {
        const delta = nextKey + 1 - obj[key].length
        for (let i = 0; i < delta; i++) {
          obj[key].push({})
        }
      }

      // recursively write the object
      obj[key][nextKey] = write(obj[key][nextKey], nextRemainingKeys, v)
    } else {
      // recursively write the object
      obj[key] = write(typeof obj[key] === 'undefined' ? {} : obj[key], remainingKeys, v)
    }
  }

  return obj
}