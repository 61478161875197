import { Epic } from 'Flux/index'
import { filter, mergeMap, of } from 'rxjs'
import { authenticationSlice } from 'Flux/Slice/Authentication/AuthenticationSlice'
import { catchError, retryWhen, withLatestFrom } from 'rxjs/operators'
import { serverIsNotAvailable } from 'Framework/Epic/ServerNotAvailable'
import { refreshAccessTokenAndRetry } from 'Framework/Epic/RefreshAccessToken'


export const ChangePasswordConfirmEpic: Epic = (
  action$,
  state$,
  { [authenticationSlice.name]: authentication, AuthenticationRepository },
) =>
  action$.pipe(
    filter(authentication.changePasswordConfirmRequest.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
        AuthenticationRepository.changePasswordConfirm(action.payload as any, state.authentication.user).pipe(
        mergeMap((response) => of(authentication.changePasswordConfirmSuccess({}))),
        retryWhen(serverIsNotAvailable()),
        catchError(
          refreshAccessTokenAndRetry({
            action$,
            store: state,
            requestAction: action,
            failureActionCreator: authentication.changePasswordConfirmFailure,
          }),
        ),
      ),
    ),
  )