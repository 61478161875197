/* eslint-disable jsx-a11y/anchor-is-valid */
import { useGetAuthenticatedUser } from 'Flux/Selector'
import { useInteraction } from '../../../Framework/View/Hooks/useInteraction'
import { Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Translation } from 'Translation/Translation'
import { Icon } from 'View/Common'
import { Menu, Transition } from '@headlessui/react'
import Actions, { useDispatch } from 'Flux'
import { authenticationSlice } from 'Flux/Slice/Authentication/AuthenticationSlice'
import { orderSlice } from 'Flux/Slice/Order/OrderSlice'


export const Navigation = () => {
  //! DEPENDENCIES
  const dispatch = useDispatch()
  const location = useLocation()
  const { translate } = Translation

  //! STATE
  const authenticatedUser = useGetAuthenticatedUser()

  //! INTERACTION
  const logout$ = useInteraction<void>()

  //! EFFECTS
  useEffect(() => {
    const logout$$ = logout$.subscribe(() => {
      dispatch(Actions[authenticationSlice.name].logout({}))
      dispatch(Actions[orderSlice.name].reset())
    })
    return () => logout$$.unsubscribe()
  }, [logout$, dispatch])

  //! HELPERS
  const isHome = ['/', ''].includes(location.pathname)

  return (
    <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
      {
        !isHome && authenticatedUser ?
          <div className='relative flex h-16 items-center justify-between'>
            <div className='flex flex-1'>
              <div className='flex flex-shrink-0'>
                <img
                  className='block h-8 w-auto'
                  src='logo.png'
                  alt='Spcfy GmbH'
                />
              </div>
            </div>
            <div className='absolute inset-y-0 right-0 flex items-center pr-2'>
              <div
                className='rounded-full bg-white-800 p-1 text-gray-400 hover:text-black hover:border-2 hover:cursor-pointer flex items-center justify-center border-black w-10 h-10'>
                <Icon type='bell-icon' className='w-8 h-8'></Icon>
              </div>
              <div className='relative ml-3'>
                <p className='font-bold uppercase'>
                  {authenticatedUser?.firstName || authenticatedUser?.lastName
                    ? `${authenticatedUser?.firstName ? authenticatedUser?.firstName : ''} ${
                      authenticatedUser?.lastName ? authenticatedUser?.lastName : ''
                    }`
                    : `${translate('user:you')}`}
                </p>
              </div>
              <Menu as='div' className='relative ml-1'>
                <div>
                  <Menu.Button
                    className='flex rounded-full bg-white-800 text-sm hover:outline-none hover:ring-2 hover:ring-white hover:ring-offset-2 hover:ring-offset-gray-800'>
                    <img
                      className='h-8 w-8 rounded-full'
                      src='profile.png'
                      alt=''
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items
                    className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <Menu.Item>
                      {({ active }) => (
                        <a href='#' onClick={() => logout$.next()}
                           className={`block px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}>
                          Logout
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div> : null
      }
    </div>
  )
}