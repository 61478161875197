import { Translation } from '../../../../Translation/Translation'
import { useEffect } from 'react'
import { useProperty } from '../../../../Framework/View'
import { useInteraction } from '../../../../Framework/View/Hooks/useInteraction'
import { Box, Button, FormControl, Input, Label, Paragraph, Toast } from '../../../Common'
import { Spacer } from '../../../Common/Layout/Spacer'
import { PageLayout } from '../../../Layout/PageLayout/PageLayout'
import Actions, { useDispatch } from 'Flux'
import { authenticationSlice } from 'Flux/Slice/Authentication/AuthenticationSlice'
import { useNavigate, useParams } from 'react-router-dom'

export const ChangePasswordConfirmPage = () => {
  const { translate } = Translation
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { token } = useParams()

  const password$ = useProperty<string>('')
  const onSubmit$ = useInteraction<void>()

  useEffect(() => {
    const onSubmit$$ = onSubmit$.subscribe(() => {
      dispatch(
        Actions[authenticationSlice.name].changePasswordConfirmRequest({
            password: password$.value,
            token:`${token}`
        }),
      )
      navigate('/')
      password$.next('')
    })
    return () => onSubmit$$.unsubscribe()
  }, [onSubmit$, password$, dispatch])

  return (
    <PageLayout>
      <Box
        title={translate('changePassword:title')}
        action={<Button variant='primary' onClick$={onSubmit$}>{translate('changePasswordConfirm:submit')}</Button>}
      >
        <Spacer>
          <Paragraph>{translate('changePasswordConfirm:welcomeText')}</Paragraph>
        </Spacer>
        <FormControl>
          <Label>Password</Label>
          <Input type='password' onChange$={password$} placeholder={translate('user:password')} />
        </FormControl>
      </Box>
    </PageLayout>
  )
}