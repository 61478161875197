import { IContact, IUser } from 'Model'
import { Observable } from 'rxjs'
import { Environment } from '../../environment'
import { post } from '../index'

export const sendContact = (data: { question: string }, user?: IUser): Observable<{
  resource: IContact
}> => {
  return post(`${Environment.API_URL}/v1/contact`, data, user)
}
