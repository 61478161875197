import { filter, mergeMap, of } from 'rxjs';
import { catchError, retryWhen, withLatestFrom } from 'rxjs/operators';
import { refreshAccessTokenAndRetry } from '../../../../Framework/Epic/RefreshAccessToken';
import { serverIsNotAvailable } from '../../../../Framework/Epic/ServerNotAvailable';
import { Epic } from '../../../index';
import { Actions } from '../../../Slice';

export const GetDatasetReportEpic: Epic = (action$, state$, { order, OrderRepository }) => action$.pipe(
  filter(Actions.order.getDatasetReportRequest.match),
  withLatestFrom(state$),
  mergeMap(([action, state]) =>
    OrderRepository.getDatasetReport(action.payload, state.authentication.user).pipe(
      mergeMap((response) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = 'redlist_reordered_raw_GBIF_otus_fasta.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(downloadLink);
        return of(order.getDatasetReportSuccess(response));
      }),
      retryWhen(serverIsNotAvailable()),
      catchError(
        refreshAccessTokenAndRetry({
          action$,
          store: state,
          requestAction: action,
          failureActionCreator: order.getDatasetReportFailure,
        }),
      ),
    ),
  ),
);
