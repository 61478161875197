import { Epic } from 'Flux'
import { of } from 'rxjs'
import { catchError, filter, mergeMap, retryWhen, switchMap, withLatestFrom } from 'rxjs/operators'
import { serverIsNotAvailable } from 'Framework/Epic/ServerNotAvailable'
import { authenticationSlice } from 'Flux/Slice/Authentication/AuthenticationSlice'

export const refreshAccessTokenEpic: Epic = (action$, store$, {
  AuthenticationRepository,
  [authenticationSlice.name]: authentication,
}) =>
  action$.pipe(
    filter(authentication.refreshAccessTokenRequest.match),
    withLatestFrom(store$),
    switchMap(([action, store]) =>
      AuthenticationRepository.refreshAccessToken({ refresh: action.payload.refreshToken }, store[authenticationSlice.name].user).pipe(
        mergeMap((response) => {
          if (response instanceof Error) {
            throw response
          }
          return of(authentication.refreshAccessTokenSuccess({ user: response }))
        }),
        retryWhen(serverIsNotAvailable()),
        catchError((error) => of(authentication.refreshAccessTokenFailure({ error }))),
      ),
    ),
  )
