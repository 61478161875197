import { Subject } from "rxjs"
import { Icon } from "../Icon/Icon"

export interface IPaginationProps {
    onClick$?: Subject<number>;
    page: number
    total: number
    take: number
}

const OFFSET = 2

export const Pagination = ({ onClick$, page, total, take }: IPaginationProps) => {
    const elementArr = Array.from(Array(1 + 2 * OFFSET).keys()).map((v) => page + v - OFFSET)
    const totalPages = Math.ceil(total / take)

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <div className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => onClick$ && page > 1 && onClick$.next(page - 1)}>
                    <span className="sr-only">Previous</span>
                    <Icon type="chevron-left-icon" className="w-5 h-5" />
                </div>
                <div className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => onClick$ && page < totalPages && onClick$.next(page + 1)}>
                    <span className="sr-only">Next</span>
                    <Icon type="chevron-right-icon" className="w-5 h-5" />
                </div>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    {
                        total > 0 ? <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">1</span> to <span className="font-medium">{take}</span> of{' '}
                            <span className="font-medium">{total}</span> results
                        </p> : <p className="text-sm text-gray-700">There are no orders to show</p>
                    }
                </div>

                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <div className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => onClick$ && page > 1 && onClick$.next(page - 1)}>
                            <span className="sr-only">Previous</span>
                            <Icon type="chevron-left-icon" className="w-5 h-5" />
                        </div>
                        <div className={`relative inline-flex items-center ${page === 1 ? "z-10 bg-green-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"} px-4 py-2 text-sm font-semibold focus:z-20`} onClick={() => onClick$ && onClick$.next(1)}>
                            1
                        </div>
                        {page > 2 + OFFSET && (
                            <span key={'...(1)'} className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                                ...
                            </span>
                        )}
                        {elementArr
                            .filter((v) => v > 1 && v < totalPages)
                            .map((pageNumber) => (
                                <div key={pageNumber} className={`relative inline-flex items-center ${page === pageNumber ? "z-10 bg-green-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"} px-4 py-2 text-sm font-semibold focus:z-20`} onClick={() => onClick$ && onClick$.next(pageNumber)}>
                                    {pageNumber}
                                </div>
                            ))}

                        {page < totalPages - OFFSET - 1 && (
                            <span key={'...(2)'} className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                                ...
                            </span>
                        )}

                        {
                            totalPages > 1 && <div key={totalPages} className={`relative inline-flex items-center ${page === totalPages ? "z-10 bg-green-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"} px-4 py-2 text-sm font-semibold focus:z-20`} onClick={() => onClick$ && onClick$.next(totalPages)}>
                                {totalPages}
                            </div>
                        }

                        <div className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => onClick$ && page < totalPages && onClick$.next(page + 1)}>
                            <span className="sr-only">Next</span>
                            <Icon type="chevron-right-icon" className="w-5 h-5" />
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}