import React, { PropsWithChildren } from 'react'

export interface ILabelProps extends PropsWithChildren {
  type?: 'bright' | 'secondary',
  valid?: boolean

}

export const Label = ({ children, type, valid = true }: ILabelProps) => {
  const secondary = `${type === 'secondary' ? 'text-secondary' : ''}`
  const bright = `${type === 'bright' ? 'text-primary-content' : ''}`

  return (
    <label className={`label ${!valid ? 'text-red-600' : ''}`}>
      <span className={`label-text ${secondary} ${bright}`}>{children}</span>
    </label>
  )
}
