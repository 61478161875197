import i18next from 'i18next'
import { Environment } from '../environment'
import { en_US } from './Resources/en_US'
import { de_DE } from './Resources/de_DE'

export type TAvailableLanguage = typeof Translation.availableLanguages[number];

i18next.init({
  debug: Environment.ENVIRONMENT === 'development',
  fallbackLng: ['en_US'],
  resources: {
    en_US,
    de_DE,
  },
})

export class Translation {
  public static readonly availableLanguages = ['de_DE', 'en_US'] as const

  public static translate = (key: string, replacement?: { count?: number } & Record<string, string>) => {
    return i18next.t(key, replacement || {})
  }

}