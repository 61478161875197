import { IPrimer, IUser } from 'Model'
import { Observable } from 'rxjs'
import { Environment } from '../../environment'
import { get, post } from '../index'

export const getPrimer = (data: { forwardSequence: string, reverseSequence: string }, user?: IUser): Observable<{
  resource: IPrimer
}> => {
  return get(`${Environment.API_URL}/v1/primer`, data, user)
}

export const createPrimerAdd = (data: IPrimer, user?: IUser): Observable<any> => {
  return post(`${Environment.API_URL}/v1/primer/create-request`, data, user)
}