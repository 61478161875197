import Actions, { useDispatch } from 'Flux'
import { useGetCurrentStep, useGetDatasetSelector, useGetOrderSelector, useGetPrimerSearchStatus } from 'Flux/Selector'
import { useGetPrimerSelector } from 'Flux/Selector/Order/GetPrimer/GetPrimerSelector'
import { EPrimerSearchStatus, orderSlice } from 'Flux/Slice/Order/OrderSlice'
import { ValidationError } from 'Framework/Error/ValidationError'
import { useInteraction } from 'Framework/View/Hooks/useInteraction'
import { IDataSet, INegativeControl, ISample } from 'Model'
import Papa from 'papaparse'
import { useEffect, useMemo, useRef, useState } from 'react'
import { BehaviorSubject, Subject } from 'rxjs'
import { Translation } from 'Translation/Translation'
import {
  Box,
  Button,
  FileInput,
  FormControl,
  Icon,
  IMessageViewModel,
  Input,
  Label,
  Messagebox,
  Paragraph,
  Stepper,
  Toast,
  Tooltip,
} from 'View/Common'
import { Spacer } from 'View/Common/Layout/Spacer'
import { STEPS } from '../HomePage'
import { CancelModal } from '../Modals/CancelModal'
import { Heading2 } from 'View/Common/Typography/Heading2'
import { ContactModal } from '../Modals/ContactModal'


type TRow = {
  'Dataset': number,
  'R1 FASTQ file': string,
  'R2 FASTQ file': string,
  'sequencing_sampleID': string,
  'customer_sampleID': string,
  'forward primer': string,
  'reverse primer': string,
};

const checkHeaders = (headers?: string[]) => {
  const correctHeaders = ['Dataset', 'R1 FASTQ file', 'R2 FASTQ file', 'sequencing_sampleID', 'customer_sampleID', 'forward primer', 'reverse primer']
  return Array.isArray(headers) && headers.every((item) => correctHeaders.includes(item))
}

const checkCustomerSamplesUnique = (array: TRow[]) => {
  const unique = new Set(array.map((item) => item.customer_sampleID).filter((item) => item !== undefined))
  return unique.size === array.length
}

const mapDatasets = (dataSets: IDataSet[] = []) => (rows: TRow[]) => {
  dataSets = JSON.parse(JSON.stringify(dataSets))

  const fileMap = dataSets.reduce((map, dataset) => {
    const files = [...(dataset.samples || []), ...(dataset.negativeControls || [])]

    for (const file of files) {
      // @ts-ignore TODO: forwardFile and reverseFile are IFileResource in this point
      map.set(`${file.forwardFile.originalFileName}-${file.reverseFile.originalFileName}`, file)
    }
    return map
  }, new Map<string, INegativeControl | ISample>())
  for (const row of rows) {
    const {
      'R1 FASTQ file': forwardFile,
      'R2 FASTQ file': reverseFile,
      customer_sampleID: customerSampleId,
      'forward primer': forwardPrimer,
      'reverse primer': reversePrimer,
    } = row
    const key = `${forwardFile}-${reverseFile}`
    const file = fileMap.get(key)
    if (!file) {
      return
    }

    file.forwardPrimer = forwardPrimer
    file.reversePrimer = reversePrimer
    file.customerSampleId = customerSampleId
  }

  return dataSets
}

const checkIfCsvHasEmptyFields = (data: TRow[]) => {
  return data.some((row) => {
    const { 'customer_sampleID': customerSampleId, 'forward primer': forwardPrimer, 'reverse primer': reversePrimer } = row
    return !customerSampleId.trim() || !forwardPrimer.trim() || !reversePrimer.trim()
  })
}

const mapCsvData = (
  dataSets: IDataSet[] | undefined,
  onError: (error: ValidationError) => void,
  onSuccess: (data: IDataSet[]) => void,
) => async (results: Papa.ParseResult<TRow>) => {
  const areHeadersCorrect = checkHeaders(results.meta.fields)
  if (!areHeadersCorrect) {
    onError(new ValidationError('INCORRECT_HEADERS'))
    return
  }

  const hasEmptyFields = checkIfCsvHasEmptyFields(results.data)
  if (hasEmptyFields) {
    onError(new ValidationError('EMPTY_FIELDS'))
    return
  }

  const isUnique = checkCustomerSamplesUnique(results.data)
  if (!isUnique) {
    onError(new ValidationError('DUPLICATE_CUSTOMER_SAMPLE_ID'))
    return
  }

  dataSets = await mapDatasets(dataSets)(results.data)
  if (!dataSets) {
    onError(new ValidationError('PRIMERS_NOT_FOUND'))
    return
  }
  onSuccess(dataSets)
}

const validateFileType = (files: File[]) => files.every((file) => file.name.includes('.csv'))

export const UploadPrimerPage = () => {
  const { translate } = Translation
  const dispatch = useDispatch()

  const currentStep = useGetCurrentStep()
  const datasets = useGetDatasetSelector()
  const currentPrimer = useGetPrimerSelector()
  const primerNotFound = useGetPrimerSearchStatus()
  const order = useGetOrderSelector()

  const onUploadPrimerFile$ = useInteraction<File[]>()
  const [primerCount, setPrimerCount] = useState<number>(0)
  const [csvError, setCSVError] = useState<boolean>(true)
  const [basePairLengthError, setBasePairLengthError] = useState<boolean>(true)
  const [forwardSequenceError, setForwardSequenceError] = useState<boolean>(true)
  const [forwardNameError, setForwardNameError] = useState<boolean>(true)
  const [reverseSequenceError, setReverseSequenceError] = useState<boolean>(true)
  const [reverseNameError, setReverseNameError] = useState<boolean>(true)
  const [referenceError, setReferenceError] = useState<boolean>(true)
  const [referenceLinkError, setReferenceLinkError] = useState<boolean>(true)

  const message$ = useRef(new Subject<{ type: 'success' | 'error' | 'warning' | 'info', message: string }>()).current
  const messagebox$ = useRef(new Subject<{ type: 'success' | 'error', message: string }>()).current
  const basePairlength$ = useRef(new BehaviorSubject('')).current
  const forwardSequence$ = useRef(new BehaviorSubject('')).current
  const forwardName$ = useRef(new BehaviorSubject('')).current
  const reverseSequence$ = useRef(new BehaviorSubject('')).current
  const reverseName$ = useRef(new BehaviorSubject('')).current
  const reference$ = useRef(new BehaviorSubject('')).current
  const referenceLink$ = useRef(new BehaviorSubject('')).current

  const onNext$ = useInteraction<void>()
  const onCancel$ = useInteraction<void>()
  const onDownload$ = useInteraction<void>()
  const onContact$ = useInteraction<void>()
  const onContactSent$ = useInteraction<void>()

  const checkPrimer$ = useInteraction<IDataSet[]>()
  const [enrichedDatasets, setEnrichedDatasets] = useState<IDataSet[]>([])

  useEffect(() => {
    const checkPrimer$$ = checkPrimer$
      .subscribe((datasets) => {
        setEnrichedDatasets(datasets)
        dispatch(Actions[orderSlice.name].getPrimerRequest(
          {
            forwardSequence: datasets[0].samples![0].forwardPrimer!.trim(),
            reverseSequence: datasets[0].samples![0].reversePrimer!.trim(),
          },
        ))
      })

    return () => checkPrimer$$.unsubscribe()
  }, [datasets, checkPrimer$, dispatch])

  // useEffect(()=>{
  //   const reverseSequence$$ = reverseSequence$
  //   .subscribe((value)=>{
  //     const result = value.replace(/[^a-z]/gi, '');
  //     setReverseSequence(result)
  //     // reverseSequence$.next(result)
  //   })
  //   return () => reverseSequence$$.unsubscribe()
  // },[])


  useEffect(() => {
    const subscription = onUploadPrimerFile$
      .subscribe((files: File[]) => {
        try {
          const validate = validateFileType(files)
          if (!validate) {
            setPrimerCount(0)
            message$.next({ type: 'error', message: 'Only .csv files are allowed' })
            return
          }

          setPrimerCount(files.length)
          if (files.length > 0) {

            Papa.parse(files[0], {
              header: true,
              skipEmptyLines: true,
              complete: mapCsvData(
                datasets,
                (error) => {
                  setPrimerCount(0)

                  switch (error.key) {
                    case 'DUPLICATE_CUSTOMER_SAMPLE_ID':
                      message$.next({ type: 'error', message: 'Customer sample id must be unique' })
                      messagebox$.next({ type: 'error', message: 'Customer sample id must be unique\nPlease fill out all primers' })
                      break
                    case 'EMPTY_FIELDS':
                      message$.next({ type: 'error', message: 'Please fill out all fields' })
                      messagebox$.next({
                        type: 'error',
                        message: 'Please fill out all fields \nCustomer sample id must be unique\nPlease fill out all primers',
                      })
                      break
                    case 'PRIMERS_NOT_FOUND':
                      message$.next({ type: 'error', message: 'Please fill out the primers' })
                      messagebox$.next({ type: 'error', message: 'Please fill out all primers' })
                      break
                    case 'INCORRECT_HEADERS':
                      // TODO add correct message
                      message$.next({ type: 'error', message: 'Primers not found' })
                      break
                  }
                },
                (data) => {
                  checkPrimer$.next(data)
                },
              ),
            })
          }
        } catch (error) {
          console.error(error)
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [checkPrimer$, datasets, message$, onUploadPrimerFile$])

  useEffect(() => {
    const onNext$$ = onNext$.subscribe(() => {

      if (primerNotFound === EPrimerSearchStatus.IN_PROGRESS) {
        if (primerCount !== 0) {
          enrichedDatasets
            .map((dataset) => dataset.samples
              ?.map((sample) => ({
                dataset, sample,
              })))
            .flat()
            .forEach((obj) => {
              if (!obj) return
              const { dataset, sample } = obj
              sample && dispatch(Actions[orderSlice.name].updateSampleRequest({
                datasetId: dataset.id,
                sampleId: sample.id,
                customerSampleId: sample.customerSampleId || '',
                reversePrimer: sample.reversePrimer || '',
                forwardPrimer: sample.forwardPrimer || '',
                minBasePairLength:(currentPrimer && Math.round((currentPrimer.basePairLength*95)/100)) || 0
              }))
            })
          
          enrichedDatasets
            .map((dataset) => dataset.negativeControls
              ?.map((negativeControl) => ({
                  dataset, negativeControl,
                }
              )))
            .flat()
            .forEach((obj) => {
              if (!obj) return
              const { dataset, negativeControl } = obj
              dispatch(Actions[orderSlice.name].updateNegativeControlRequest({
                datasetId: dataset.id,
                negativeControlId: negativeControl.id,
                customerSampleId: negativeControl.customerSampleId || '',
                reversePrimer: negativeControl.reversePrimer || '',
                forwardPrimer: negativeControl.forwardPrimer || '',
                minBasePairLength:(currentPrimer && Math.round((currentPrimer.basePairLength*95)/100)) || 0
              }))
            })
          dispatch(
            Actions[orderSlice.name].stepNext(),
          )
        }
        primerCount === 0 && setCSVError(false)
        primerCount === 0 &&
        message$.next({ type: 'error', message: 'Uploading csv is mandatory' })
        setPrimerCount(0)
        onUploadPrimerFile$.next([])
      } else {
        if (forwardName$.value !== '' && forwardSequence$.value !== '' && reverseName$.value !== '' && reverseSequence$.value !== '' && reference$.value !== '' && referenceLink$.value !== '' && basePairlength$.value !== '') {
          primerNotFound === EPrimerSearchStatus.NOT_FOUND && dispatch(
            Actions[orderSlice.name].createPrimerAddRequest({
              amplicon: 'CO1',
              forwardName: forwardName$.value,
              basePairLength: parseInt(basePairlength$.value),
              forwardSequence: forwardSequence$.value,
              reverseName: reverseName$.value,
              reverseSequence: reverseSequence$.value,
              reference: reference$.value,
              referenceLink: referenceLink$.value,
            }),
          )
          if (primerNotFound === EPrimerSearchStatus.SUCCESS) {
            dispatch(
              Actions[orderSlice.name].reset(),
            )
          }
        } else {
          forwardName$.value === '' && setForwardNameError(false)
          forwardName$.value !== '' && setForwardNameError(true)
          forwardSequence$.value === '' && setForwardSequenceError(false)
          forwardSequence$.value !== '' && setForwardSequenceError(true)
          reverseName$.value === '' && setReverseNameError(false)
          reverseName$.value !== '' && setReverseNameError(true)
          reverseSequence$.value === '' && setReverseSequenceError(false)
          reverseSequence$.value !== '' && setReverseSequenceError(true)
          reference$.value === '' && setReferenceError(false)
          reference$.value !== '' && setReferenceError(true)
          referenceLink$.value === '' && setReferenceLinkError(false)
          referenceLink$.value !== '' && setReferenceLinkError(true)
          basePairlength$.value === '' && setBasePairLengthError(false)
          basePairlength$.value !== '' && setBasePairLengthError(true)
          message$.next({ type: 'error', message: 'All fields are mandatory' })
          messagebox$.next({ type: 'error', message: 'All fields are mandatory' })
        }
      }
    })
    return () => onNext$$.unsubscribe()
  }, [onNext$, dispatch, primerCount, onUploadPrimerFile$, primerNotFound, enrichedDatasets,currentPrimer])


  useEffect(() => {
    const onDownload$$ = onDownload$.subscribe(() => {
      const convert = (dataset: IDataSet) => {
        return (sample: ISample | INegativeControl): TRow => ({
          Dataset: dataset.localIndex,
          'R1 FASTQ file': sample.forwardFile.originalFileName,
          'R2 FASTQ file': sample.reverseFile.originalFileName,
          'sequencing_sampleID': sample.forwardFile.originalFileName.split('_')[0].replaceAll('-', '_'),
          'customer_sampleID': '',
          'forward primer': '',
          'reverse primer': '',
        })
      }
      const sort = datasets.reduce((rows, dataset) => {

        return [
          ...rows,
          ...(dataset.samples || []).map(convert(dataset)),
          ...(dataset.negativeControls || []).map(convert(dataset)),
        ]
      }, [] as (TRow)[])
      const blob = new Blob([Papa.unparse(sort, { delimiter: ';' })], { type: 'plain/text' })
      const aElement = document.createElement('a')
      aElement.setAttribute('download', 'template.csv')
      const href = URL.createObjectURL(blob)
      aElement.href = href
      aElement.setAttribute('target', '_blank')
      aElement.click()
      URL.revokeObjectURL(href)
    })
    return () => onDownload$$.unsubscribe()
  }, [datasets, onDownload$])

  const isSamePrimerMessage$ = useMemo(() => {
    if (!currentPrimer) {
      return
    }

    const isPrimerIncluded = [
      ...enrichedDatasets.map((dataset) => dataset.samples),
      ...enrichedDatasets.map((dataset) => dataset.negativeControls),
    ]
      .flat()
      .every((sample) =>
        sample !== undefined
        && sample.forwardPrimer?.endsWith(currentPrimer.forwardSequence)
        && sample.reversePrimer?.endsWith(currentPrimer.reverseSequence),
      )

    if (!isPrimerIncluded) {
      return new BehaviorSubject<IMessageViewModel>({
        type: 'error',
        message: 'We support one primer within one order. If you want to use different primers, please create a new order.',
      })
    }
  }, [enrichedDatasets, currentPrimer])

  useEffect(() => {

    const subscription = onContactSent$.subscribe(() => {
      message$.next({
        type: 'success',
        message: 'Your message has been successfully sent',
      })
    })
    return () => subscription.unsubscribe()

  }, [onContactSent$])


  return (
    <div>
      <Box
        action={
          <>
            <Button variant='primary'
                    onClick$={onNext$}>{primerNotFound === EPrimerSearchStatus.SUCCESS ? 'Start new order' : translate('home:next')}</Button>
            {primerNotFound !== EPrimerSearchStatus.SUCCESS ?
              <Button variant='cancel' onClick$={onCancel$}>{translate('home:back')}</Button> : null}
          </>
        }
      >
        <Stepper steps={STEPS} currentStep={currentStep} />
        <Spacer />
        <div className='flex justify-end'>
          <Button variant='contact' onClick$={onContact$}>Contact</Button>
        </div>
        <div className='flex justify-center items-center'>
          <Heading2>Order name: {order.name}</Heading2>
        </div>
        <div className='text-center'>
          <Paragraph>Please download the prefilled csv file and provide us with the necessary information – assign unique customer_IDs,
            please provide us with the exact forward and reverse primer sequences including any MID and/or Fusion tags used.</Paragraph>
        </div>
        {
          primerNotFound === EPrimerSearchStatus.IN_PROGRESS ? <div>
              <FormControl>
                <Label>Download template primer list: *</Label>
                <div className='flex'>
                  <div
                    onClick={() => onDownload$.next()}
                    className='flex justify-center items-center w-48 h-10 border-2 bg-green-200 border-gray-400 hover:border-green-400 rounded-full hover:cursor-pointer text-green-600'>
                    <Icon type='download-icon' className='w-8 h-8 text-green-600'></Icon>Download template
                  </div>
                  <Tooltip
                    tooltip='Please download the csv template file and fill in the required information'>
                    <Icon type='information-circle-icon' className='h-8 w-8'></Icon>
                  </Tooltip>
                </div>
              </FormControl>
              <FormControl>
                <Label valid={csvError}>Upload filled primer list: *</Label>
                <div className='flex'>
                  <FileInput onChange$={onUploadPrimerFile$} isValid={false} onReset$={onNext$} isMultiple={false} fileType='.csv'
                             fileCounter={primerCount} fileAccept='.csv' />
                  <Tooltip
                    tooltip='Please re-upload the filled out csv file in order to launch the detection of the primers and to continue with the preprocessing of your samples.'>
                    <Icon type='information-circle-icon' className='h-8 w-8'></Icon>
                  </Tooltip>
                </div>
              </FormControl>
              <FormControl>
                <Label>Results</Label>
                {currentPrimer && (<>
                  <div className='text-green-600 font-bold pb-4 text-xxs xs:text-xs md:text-base'>
                    The provided primer sequence have been recognized by our primer database.
                  </div>
                  <div className='max-w-fit text-xxs xs:text-xs md:text-base'>
                    <div className='flex justify-between'>
                      <div className='flex-col'>
                        <div className='text-green-600 font-bold'>
                          Primer Name:
                        </div>
                        <div className='text-green-600 font-bold'>
                          Reference publication:
                        </div>
                        <div className='text-green-600 font-bold'>
                          Length of amplicon (bp):
                        </div>
                      </div>
                      <div className='flex-col mx-10'>
                        <div className='text-green-600'>
                          {currentPrimer.forwardName} / {currentPrimer.reverseName}
                        </div>
                        <div className='text-green-600'>
                          {currentPrimer.reference}
                        </div>
                        <div className='text-green-600'>
                          {currentPrimer.basePairLength}
                        </div>
                      </div>
                    </div>
                  </div>
                </>)}
              </FormControl>
              <Spacer />
              <Messagebox messageInterval={1000000} message$={messagebox$}></Messagebox>
            </div> :
            primerNotFound === EPrimerSearchStatus.NOT_FOUND ?
              <div>
                <div className='p-2'>
                  <Paragraph color={'error'}>We could not find the provided primers in our database. Please provide the base pair length
                    (bp) of the amplicon to enable quality filtering.</Paragraph>
                </div>
                <FormControl>
                  <Label valid={basePairLengthError}>Length of amplicon: *</Label>
                  <div className='w-1/2 flex justify-center items-center'>
                    <Input type='text' onChange$={basePairlength$}
                           placeholder={'Base pair length'} pattern='[^0-9]' />
                    <Tooltip
                      tooltip='Base Pair Length of amplicon'>
                      <Icon type='information-circle-icon' className='h-8 w-8'></Icon>
                    </Tooltip>
                  </div>
                </FormControl>
                <div className='p-2'>
                  <Paragraph color={'error'}>If you do not want to reenter the base pair length of the used amplicon again next time, we
                    recommend you to provide us with the additional information below and we will store this data to automatically find the
                    base pair length next time.</Paragraph>
                </div>
                <div className='flex justify-between'>
                  <FormControl>
                    <Label valid={forwardSequenceError}>Forward sequence: *</Label>
                    <Input type='text' onChange$={forwardSequence$}
                           placeholder={'Forward sequence'} pattern='[^A-Za-z]' />
                  </FormControl>
                  <FormControl>
                    <Label valid={forwardNameError}>Forward name: *</Label>
                    <Input type='text' onChange$={forwardName$}
                           placeholder={'Forward name'} />
                  </FormControl>
                </div>
                <div className='flex justify-between'>
                  <FormControl>
                    <Label valid={reverseSequenceError}>Reverse sequence: *</Label>
                    <Input type='text' onChange$={reverseSequence$}
                           placeholder={'Reverse sequence'} pattern='[^A-Za-z]' />
                  </FormControl>
                  <FormControl>
                    <Label valid={reverseNameError}>Reverse name: *</Label>
                    <Input type='text' onChange$={reverseName$}
                           placeholder={'Reverse name'} />
                  </FormControl>
                </div>
                <div className='flex justify-between'>
                  <FormControl>
                    <Label valid={referenceError}>Reference citation: *</Label>
                    <Input type='text' onChange$={reference$} placeholder={'Reference citation'} />
                  </FormControl>
                  <FormControl>
                    <Label valid={referenceLinkError}>Reference DOI: *</Label>
                    <Input type='text' onChange$={referenceLink$} placeholder={'Reference DOI'} />
                  </FormControl>
                </div>
                <Spacer />
                <Messagebox messageInterval={1000000} message$={messagebox$}></Messagebox>
              </div> :
              primerNotFound === EPrimerSearchStatus.SUCCESS ?
                <div>
                  <div className='p-2 text-center'>
                    <Paragraph>Thank you for submitting a new primer to the spcfy database. Our admin team will be contacting you via eMail
                      as soon as it has been approved and included.</Paragraph>
                  </div>
                  <Spacer />
                </div> : null
        }
      </Box>
      <CancelModal visibileModal$={onCancel$}></CancelModal>
      <Toast messageInterval={5000} message$={message$}></Toast>
      <ContactModal visibileModal$={onContact$} onTrigger$={onContactSent$}></ContactModal>
      {isSamePrimerMessage$ && <Toast messageInterval={10000} message$={isSamePrimerMessage$}></Toast>}
    </div>
  )
}